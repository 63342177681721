import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import withStyles from "@material-ui/core/styles/withStyles";
import {Col, FormGroup, Row} from "react-bootstrap";
import Api from "../../../../services/api";
import {Accordion, AccordionSummary, AccordionDetails} from "diginet-core-ui/components";
import Config from "../../../../config";
import {TextField} from "@material-ui/core";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Attachments from "../../../common/attachments/attachments";
import _ from "lodash";
import moment from "moment"
import InputMask from "react-input-mask";
import UserImage from "../../../common/user/user-image";
import GridActionBar from "../../../grid-container/grid-actionbar";
import Icons from "../../../common/icons";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import W29F2056 from "../W29F2056/W29F2056";

import {FormHelperText, IconButton} from "@material-ui/core";
import {
    DatePicker,
    Dropdown,
    TextInput,
    Checkbox,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "diginet-core-ui/components";

const styles = () => ({
    accordion: {
        boxShadow: 'none'
    },
    accordionSummary: {
        minHeight: '37px !important',
        "& .MuiAccordionSummary-content": {
            margin: 0,
            "&.Mui-expanded": {
                margin: 0
            }
        }
    },
    accordionDetails: {
        display: 'block',
        padding: '0'
    },
    flexAlignCenter: {
        display: "flex",
        alignItems: "center"
    },
    rmUnderLine: {
        "& > .underlined": {
            "& > div > input.underlined": {
                cursor: "auto !important",
            },
            "&:before,&:after": {
                content: "none",
            },
        }
    },
    dangerColor: {
        color: "#f44336"
    }
});

const W29F2061 = ({
                      classes,
                      modeForm = "add",
                      iPermission = 0,
                      data = {},
                      dataCboShift = [],
                      isShowModal = false,
                      generalActions,
                      onClose
                  }) => {
    const [dataForm, setDataForm] = useState({
        ShiftID: "",
        OTFrom: "",
        OTTo: "",
        AttendanceDate: null,
        IsOverTime: "",
        OTType: {
            OTTypeID: "",
            OTTypeName: "",
        },
        IsConfirm01: false,
        IsConfirm02: false,
        IsConfirm03: false,
        IsConfirm04: false,
        IsConfirm05: false,
        ReasonOT: "",
        Note: "",
    });
    const [dataGrid, setDataGrid] = useState([]);
    const [dataCboOTType, setDataCboOTType] = useState([]);
    const [dataModalOvertimeEdit, setDataModalOvertimeEdit] = useState({});
    const [dataCaption, setDataCaption] = useState([]);
    const [dataOtHour, setDataOtHour] = useState({
        Status: 0,
        OTHour: 0,
        IsOverTime: 0,
    });
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [errors, setErrors] = useState({
        AttendanceDate: "",
        OTFrom: "",
        OTTo: "",
        OTTHour: "",
        ShiftID: "",
        dataGrid: ""
    });

    const [isVisibleCboOTType, setVisibleCboOTType] = useState(0);

    const [isLoadingForm, setLoadingForm] = useState(false);
    const [isLoadingGrid, setLoadingGrid] = useState(false);
    const [isLoadingOTType, setLoadingOTType] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isLoadingAttachment, setLoadingAttachment] = useState(false);
    const [isExpanded, setExpanded] = useState(true);
    const [isShowModalOvertimeEdit, setShowModalOvertimeEdit] = useState(false);
    const [isShowModalEmployeeChosen, setShowModalEmployeeChosen] = useState(false);

    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const timerLoadingForm = useRef(0);
    const timerLoadingGrid = useRef(0);
    const fieldRequired = ["AttendanceDate", "ShiftID", "OTFrom", "OTTo", "dataGrid"];

    const attendanceDate = _.get(dataForm, "AttendanceDate", _.get(data, "AttendanceDate", ""));
    const employeeID = _.get(data, "EmployeeID", "");
    const shiftID = _.get(dataForm, "ShiftID", _.get(data, "ShiftID", ""));
    const oTType = _.get(dataForm, "OTType", {})
    const oTFrom = _.get(dataForm, "OTFrom", "");
    const oTTo = _.get(dataForm, "OTTo", "");
    const reasonOT = _.get(dataForm, "ReasonOT", "");

    useEffect(() => {
        if (modeForm !== "add") {
            loadAttachments();
            loadForm(0, setLoadingForm).then((resData) => {
                const getDataForm = _.get(resData, "[0]", {});
                if (resData) {
                    setDataForm({
                        ..._.omit(getDataForm, ["OTTypeID", "OTTypeName"]),
                        OTType: {
                            OTTypeID: _.get(getDataForm, "OTTypeID", ""),
                            OTTypeName: _.get(getDataForm, "OTTypeName", "")
                        }
                    });
                }
            });
            loadForm(1, setLoadingGrid).then((resData) => {
                if (resData) {
                    setDataGrid(_.map(resData, item => ({...item, isNew: false})))
                }
            });
        }
        loadData("/w29f2011/get-ot-type").then(resData => {
            if (resData) setVisibleCboOTType(_.get(resData, "IsUseOTType", 0));
        });
        loadData("/w29f2056/load-caption").then(resData => {
            if (resData) setDataCaption(_.filter(resData, ["Disabled", true]))
        });
        return () => {
            if (timerLoadingForm.current) clearTimeout(timerLoadingForm.current);
            if (timerLoadingGrid.current) clearTimeout(timerLoadingGrid.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timerLoadingOTType = 0;
        const oTTime = _.split(_.get(data, "OTHour", ""), "-");
        const oTFrom = _.get(dataForm, "OTFrom", _.trim(oTTime[0]));
        const oTTo = _.get(dataForm, "OTTo", _.trim(oTTime[1]))
        const params = {
            OTFrom: _.replace(oTFrom, ":", ""),
            OTTo: _.replace(oTTo, ":", ""),
            Language: Config.language || 84,
        };
        if (oTFrom.indexOf("_") === -1 && oTTo.indexOf("_") === -1 && oTFrom && oTTo) {
            setLoadingOTType(true);
            loadData("/w29f2061/load-ot-type", {
                ...params,
                TransID: _.get(dataForm, "TransID", _.get(data, "TransID", "")),
                Mode: 0,
            }).then(resData => {
                timerLoadingOTType = setTimeout(() => {
                    setLoadingOTType(false)
                }, 300);
                if (resData) setDataCboOTType(resData)
            });
            loadData("/w29f2056/load-ot-hour", {
                ...params,
                EmployeeID: employeeID,
                AttendanceDate: attendanceDate ? moment(attendanceDate).format("YYYY-MM-DD") : null,
                ShiftID: shiftID,
                Mode: 2,
            }).then(resData => {
                if (_.get(resData, "Status", 0) === 0) {
                    let message = ""
                    setDataOtHour(resData);
                    if (_.isEmpty(shiftID)) message = Config.lang("Vui_long_chon_ca");
                    setErrors({...errors, OTTHour: message});
                }
                if (_.get(resData, "Status", 0) === 1) {
                    const message = _.get(resData, "Message", "")
                    setErrors({...errors, OTTHour: message});
                    setDataOtHour({
                        Status: 0,
                        OTHour: 0,
                        IsOverTime: 0
                    })
                }
            })
        }
        return () => {
            if (timerLoadingOTType) clearTimeout(timerLoadingOTType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oTFrom, oTTo, attendanceDate, shiftID]);

    const parseConfirmField = (item) => _.chain(item).pickBy((o, k) => _.startsWith(k, "IsConfirm")).mapValues((v, k, o) => o[k] = _.toNumber(v)).value();

    const formatDate = (date, format = "DD/MM/YYYY") => moment(date).isValid() ? moment(date).format(format) : null;

    const handleDataParams = (typeData) => {
        let dataParams = [];
        switch (typeData) {
            case "dataForm":
                dataParams = _.chain([dataForm])
                    .map(item => ({
                        ..._.omit(item, "OTType"),
                        ...oTType,
                        ...parseConfirmField(item) ?? {},
                        AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
                        OTHour: _.toNumber(_.get(dataOtHour, "OTHour", 0)),
                        OTFrom: _.replace(oTFrom, ":", ""),
                        OTTo: _.replace(oTTo, ":", ""),
                        Note: reasonOT,
                    }))
                    .get("[0]", {})
                    .value();
                break;
            case "dataGrid":
                dataParams = _.map(dataGrid, item => ({
                    ..._.omit(item, "isNew"),
                    ...parseConfirmField(item) ?? {},
                    OTHour: _.toNumber(_.get(item, "OTHour", 0)),
                    AttendanceDate: formatDate(_.get(item, "AttendanceDate", ""), "YYYY-MM-DD")
                }))
                break;
            default:
                break;
        }
        return dataParams
    };

    const checkMaskRules = (data) => {
        if (!data) return null;
        return data?.startsWith('2');
    };

    const checkValidFRequired = (field, value = "", isOnStore = false) => {
        let errorText = "";
        let isInValid = false;
        let valueCheck = value ? value : _.get(dataForm, field, "");
        let otherErrorField = {};
        switch (field) {
            case "AttendanceDate":
                errorText = Config.lang("Truong_nay_bat_buoc_chon");
                isInValid = _.isEmpty(valueCheck);
                otherErrorField = {ShiftID: ""}
                if (isInValid) {
                    otherErrorField = {ShiftID: Config.lang("Vui_long_chon_ngay_tang_ca")}
                }
                break;
            case "ShiftID":
                errorText = Config.lang("Truong_nay_bat_buoc_chon");
                isInValid = _.isEmpty(valueCheck);
                if (_.isEmpty(attendanceDate)) {
                    errorText = Config.lang("Vui_long_chon_tang_ca")
                }
                otherErrorField = {OTTo: "", OTFrom: ""};
                if (isInValid) {
                    otherErrorField = {
                        OTHour: Config.lang("Vui_long_chon_ca"),
                    }
                }
                break;
            case "OTFrom":
            case "OTTo":
                const valueValid = _.chain(valueCheck).split('');
                if (valueValid.every(val => val === "_").value() || valueValid.every(val => val === "").value()) {
                    errorText = Config.lang("Truong_nay_bat_buoc_nhap");
                    isInValid = valueValid.some(val => val === "").value() || valueValid.every(val => val === "_").value();
                    if (_.isEmpty(shiftID)) {
                        otherErrorField = {
                            OTHour: Config.lang("Vui_long_chon_ca"),
                        }
                    }
                }
                break;
            case "dataGrid":
                errorText = Config.lang("Vui_long_chon_nhan_vien");
                isInValid = _.size(dataGrid) > 0 ? _.every(dataGrid, ["Deleted", 1]) : true;
                break;
            default:
                break;
        }
        if (isOnStore) otherErrorField = {};
        return ({[field]: isInValid ? errorText : "", ...otherErrorField})
    };

    const handleResponse = (res) => {
        const resData = _.get(res, "data", {});
        const resMessageErr = _.get(res, "message", "") || _.get(resData, "Message", "");
        if (resMessageErr) Config.popup.show("INFO", resMessageErr);
        return resData
    };

    const handleChangeForm = (key, e) => {
        let value = _.get(e, "value", _.get(e, "target.value", "")) || "";
        let keyField = key;
        let otherDataField = {};
        if (key === "Caption") {
            keyField = _.get(e, "target.name", "");
            value = _.get(e, "target.checked", false);
        }
        const getFieldInvalid = checkValidFRequired(key, value);
        if (key === "OTType") value = _.get(_.filter(dataCboOTType, item => _.get(item, "OTTypeID", "") === value), "[0]", {});
        if (key === "ShiftID" || key === "AttendanceDate") otherDataField = {OTFrom: "", OTTo: "", OTHour: 0};
        if (_.includes(fieldRequired, key)) setErrors({...errors, ...getFieldInvalid})
        if (keyField) setDataForm({...dataForm, ...otherDataField, [keyField]: value});
    };

    const loadAttachments = () => {
        const params = {
            KeyID: _.get(data, "TransID", "")
        };
        setLoadingAttachment(true);
        generalActions.getAttachmentsByTransID(params, (err, resData) => {
            setLoadingAttachment(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) setDataOldAttachments(resData)
        });
    };

    const loadForm = async (Mode, setLoading) => {
        const params = {
            FormID: "W29F2061",
            TransID: _.get(data, "TransID", null),
            Mode,
        };
        try {
            setLoading(true);
            const res = await Api.put("/w29f2061/load-form", params);
            switch (Mode) {
                case 0:
                    timerLoadingGrid.current = setTimeout(() => setLoading(false), 300);
                    break;
                case 1:
                    timerLoadingForm.current = setTimeout(() => setLoading(false), 300);
                    break;
                default:
                    break;
            }
            return handleResponse(res);
        } catch (e) {
            return e;
        }
    };

    const loadData = async (url = "", params = {}) => {
        if (!url) return false;
        try {
            const res = await Api[_.isEmpty(params) ? "get" : "put"](url, params);
            return handleResponse(res);
        } catch (e) {
            return e
        }
    };

    const onChosenEmployees = (dataEmployeesID) => {
        const dataParamsOfForm = handleDataParams("dataForm");
        const params = {
            FormID: "W29F2060",
            ..._.omit(dataParamsOfForm, ["ApprovalStatus", "ReasonOT", "ShiftID", "ShiftName", "TransID", "OTTypeName"]),
        };
        setLoadingGrid(true)
        loadData("/w29f2061/load-choose", params).then(resData => {
            setLoadingGrid(false);
            if (resData) {
                const dataNonOverlapping = _.uniqBy([...dataGrid, ...resData], "EmployeeID");
                setDataGrid(dataNonOverlapping)
            }
        });
        if (_.size(dataEmployeesID) > 1) setErrors({...errors, dataGrid: ""})
        setShowModalEmployeeChosen(false);
    };

    const onChangeAttachments = (e) => {
        attachments.current = _.get(e, "uploadedFiles", []);
        if (e.deletedFiles && _.size(e.deletedFiles) > 0) {
            deletedFile.current = [...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(_.filter(dataOldAttachments, att => _.indexOf(_arrRemove, att.AttachmentID) < 0));
        }
    };

    const onUploading = value => {
        setUploading(value)
    };

    const onEditRow = ({data: dataRow}) => {
        setDataModalOvertimeEdit(dataRow);
        setShowModalOvertimeEdit(true);
    }

    const onDeleteRow = ({data: dataRow}) => {
        const getEmployeeID = _.get(dataRow, "EmployeeID", "");
        const index = _.findIndex(dataGrid, ["EmployeeID", getEmployeeID]);
        const isNew = _.get(dataRow, "isNew", true);
        let data;
        if (isNew) {
            data = _.filter(dataGrid, item => item.EmployeeID !== getEmployeeID);
        } else {
            data = [
                ...dataGrid.slice(0, index),
                {
                    ...dataGrid[index],
                    Deleted: 1
                },
                ...dataGrid.slice(index + 1)
            ];
        }
        if (_.every(data, ["Deleted", 1])) {
            setErrors({...errors, dataGrid: Config.lang("Vui_long_chon_nhan_vien")})
        }
        setDataGrid(data);
    }

    const onCloseModal = (data, isReload) => {
        const getEmployeeID = _.get(data, "EmployeeID", "");
        const getOTFrom = _.get(data, "OTFrom", "");
        const getOTTo = _.get(data, "OTTo", "");
        const getOTTypeName = _.get(_.filter(dataCboOTType, item => _.get(item, "OTTypeID", "") === _.get(data, "OTTypeID", "")), "[0]OTTypeName", "")
        const index = _.findIndex(dataGrid, ["EmployeeID", getEmployeeID]);
        setShowModalOvertimeEdit(false);
        if (!getEmployeeID) return false;
        if (isReload) {
            setDataGrid([
                    ...dataGrid.slice(0, index),
                    {
                        ...dataGrid[index],
                        ...data,
                        OTTypeName: getOTTypeName,
                        OTFrom: getOTFrom.substring(0, 2) + ":" + getOTFrom.substring(2, getOTFrom.length),
                        OTTo: getOTTo.substring(0, 2) + ":" + getOTTo.substring(2, getOTTo.length),
                    },
                    ...dataGrid.slice(index + 1)
                ]
            )
        }
    }

    const getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: _.get(att, "url", ""),
                FileName: _.get(att, "fileName", ""),
                FileSize: _.get(att, "fileSize", ""),
                FileExt: _.get(att, "fileExt", ""),
            });
        });
        if (dataOldAttachments && _.size(dataOldAttachments) > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const onStore = async () => {
        const arrAttachments = getAttachments(attachments.current);
        const dataParamsOfForm = handleDataParams("dataForm");
        const dataParamsOfGrid = handleDataParams("dataGrid")
        let params = {
            ...dataParamsOfForm,
            Language: Config.language || "84",
            IsOverTime: _.get(dataOtHour, "IsOverTime", 0),
            data: JSON.stringify(dataParamsOfGrid),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        const dataError = _.chain(fieldRequired)
            .map(item => checkValidFRequired(item, "", true))
            .reduce((acc, item) => {
                acc[_.keys(item)] = item[_.keys(item)];
                return acc;
            }, {})
            .value();
        setErrors(dataError);
        if (_.some(_.values(dataError), text => !_.isEmpty(text))) {
            return false;
        }
        if (_.chain(dataParamsOfGrid).filter(['Deleted', 0]).uniqBy("ShiftID").size().value() > 1) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec") + ". " + Config.lang("Khong_duoc_phep_luu"))
            return false;
        }
        if (_.some(dataParamsOfGrid, item => _.get(item, "ShiftID", "") === "")) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_chua_co_ca_lam_viec") + ". " + Config.lang("Khong_duoc_phep_luu"))
            return false;
        }
        setLoadingForm(true);
        const res = await Api[modeForm === "add" ? "post" : "put"](`/w29f2061/${modeForm}`, params);
        setLoadingForm(false);
        const resData = handleResponse(res);
        if (_.get(resData, "Status", 1) === 0) {
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            onClose(true);
            return true;
        } else {
            if(_.get(resData, "MsgAsk", "") === 0) {
                Config.popup.show("INFO", _.get(resData, "Message", ""));
            }
            if(_.get(resData, "MsgAsk", "") === 1) {
                Config.popup.show('YES_NO', _.get(resData, "Message", "") , () => {
                    params = {...params,code: _.get(resData, "code", "") };
                    _saveData(params, modeForm);
                });
            }
        }
    };

    const _saveData = async (params, modeForm) => {
        setLoadingForm(true);
        const res = await Api.put(`/w29f2061/confirm-${modeForm}`, params);
        setLoadingForm(false);
        const resData = handleResponse(res);
        if (_.get(resData, "Status", 1) === 0) {
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            onClose(true);
            return true;
        }
    };

    const loadUpdateGrid = (Choice = 0) => {
        const dataParamsOfForm = handleDataParams("dataForm");
        const dataParamsOfGrid = handleDataParams("dataGrid");
        const params = {
            ...dataParamsOfForm,
            Choice,
            data: JSON.stringify(dataParamsOfGrid),
        }
        setLoadingGrid(true);
        loadData("/w29f2055/update", params).then(res => {
            if (res) {
                const dataGridUpdated = _.map(dataGrid, item => _.extend(item, _.find(res, {EmployeeID: item.EmployeeID})));
                setDataGrid(dataGridUpdated);
            }
        })
        if (timerLoadingGrid.current) clearTimeout(timerLoadingGrid.current);
        timerLoadingGrid.current = setTimeout(() => {
            setLoadingGrid(false);
        }, 300)
    }

    const onUpdateGrid = () => {
        const isAddEmp = _.every(dataGrid, item => !!_.get(item, "AddEmp", 0));
        Config.popup.show({
                ...(!isAddEmp ? {
                    yesText: Config.lang("Tat_ca"),
                    noText: Config.lang("Moi_bo_sung"),
                } : {}),
                type: "YESNO"
            },
            isAddEmp ? Config.lang("Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien") : Config.lang("Ban_co_muon_cap_thong_tin_tang_ca_nhan_vien"),
            () => loadUpdateGrid(),
            () => !isAddEmp && loadUpdateGrid(1)
        );
    };

    const renderAvatar = ({data}) => {
        return (<div className={"display_row align-center"}>
            <UserImage data={data} width={32} height={32}/>
        </div>)
    }

    const renderAction = (e) => {
        return <GridActionBar>
            <div className="">
                <IconButton
                    disabled={modeForm === "view"}
                    aria-label="view"
                    style={{padding: 0, width: 30, height: 30, marginRight: 4}}
                    onClick={() => onEditRow(e)}
                >
                    <Icons name={"edit"}/>
                </IconButton>
                <IconButton
                    disabled={modeForm === "view"}
                    aria-label="delete"
                    style={{padding: 0, width: 30, height: 30, marginRight: 3}}
                    onClick={() => onDeleteRow(e)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        </GridActionBar>
    }

    const checkFieldError = (group = "All") => {
        let objErrors = errors;
        switch (group) {
            case "Info":
                objErrors = _.omit(errors, "dataGrid");
                break;
            case "Grid":
                objErrors = _.pick(errors, "dataGrid");
                break;
            default:
                break;
        }
        return _.some(_.values(objErrors), text => !_.isEmpty(text));
    }

    const checkRuleOTFrom = oTFrom && checkMaskRules(dataForm.OTFrom);
    const checkRuleOTTo = oTTo && checkMaskRules(dataForm.OTTo);
    const isErrors = checkFieldError();
    const isErrorInfo = checkFieldError("Info");
    const isErrorDataGrid = checkFieldError("Grid");

    return <>
        {isShowModalEmployeeChosen &&
        <W09F6000
            loadParams={{ShiftID: shiftID, AttendanceDate: attendanceDate}}
            open={isShowModalEmployeeChosen} dataDefaults={[]} FormID={"W29F2060"}
            onChosen={onChosenEmployees}
            onClose={() => setShowModalEmployeeChosen(false)}/>
        }
        {isShowModalOvertimeEdit &&
        <W29F2056
            iPermission={iPermission}
            open={isShowModalOvertimeEdit}
            onClose={(data, isReload) => onCloseModal(data, isReload)}
            data={{
                rowData: dataModalOvertimeEdit,
                dataCaption,
                dataCboOtType: dataCboOTType,
                mode: "edit",
                paramOtType: true
            }}
        />
        }
        <Modal pressESCToClose width={"100%"} open={isShowModal} onClose={() => onClose()}>
            <ModalHeader>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{marginRight: 10}}>{Config.lang("Dang_ky_tang_ca_ho")}</span>
                    {modeForm !== "view" &&
                    <Button label={Config.lang('Chon_nhan_vien')}
                            startIcon={"Person"}
                            color={"primary"}
                            viewType={"text"}
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            disabled={iPermission < 1 || _.isEmpty(shiftID) || _.isEmpty(attendanceDate)}
                            onClick={() => setShowModalEmployeeChosen(true)}
                    />}
                </div>
            </ModalHeader>
            <ModalBody style={{height: "100vh"}}>
                <FormGroup style={{marginBottom: 7}}>
                   ` <Accordion
                        expand
                        onCollapse={() => setExpanded(false)}
                        onExpand={() => setExpanded(true)}
                    >
                        <AccordionSummary   >
                            <div style={{marginRight: 15}}>
                                <span className={isErrorInfo ? classes.dangerColor : ""}>{Config.lang("Thong_tin")}
                                    <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span>
                                            </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>`
                            <Row>
                                <Col xs={12} sm={6}>
                                    <DatePicker
                                        error={errors["AttendanceDate"]}
                                        label={Config.lang("Ngay_tang_ca")}
                                        actionIconAt={'start'}
                                        placeholder={'DD/MM/YYYY'}
                                        displayFormat={'DD/MM/YYYY'}
                                        value={attendanceDate}
                                        viewType={'underlined'}
                                        onChange={(e) => handleChangeForm("AttendanceDate", e)}
                                        style={{margin: 0}}
                                        clearAble
                                        readOnly={modeForm !== "add"}
                                        disabled={isLoadingForm}
                                        required={true}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Dropdown
                                        error={errors["ShiftID"]}
                                        readOnly={modeForm !== "add"}
                                        disabled={_.isEmpty(attendanceDate) || isLoadingForm}
                                        required={true}
                                        allowSearch
                                        dataSource={dataCboShift}
                                        valueExpr={"ShiftID"}
                                        displayExpr={"ShiftName"}
                                        label={Config.lang("Ca")}
                                        onChange={e => {
                                            const value = _.get(e, "value", "");
                                            if (_.isEmpty(value)) {
                                                setDataForm({
                                                    ...dataForm,
                                                    ShiftID: "",
                                                    OTFrom: "__:__",
                                                    OTTo: "__:__"
                                                })
                                            } else {
                                                handleChangeForm("ShiftID", e);
                                                setDataGrid([]);
                                            }
                                        }}
                                        value={shiftID}
                                        clearAble
                                        viewType={'underlined'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {isVisibleCboOTType > 0 &&
                                <Col xs={12} sm={6}>
                                    <Dropdown
                                        loading={isLoadingOTType}
                                        disabled={isLoadingForm}
                                        allowSearch
                                        readOnly={modeForm === "view"}
                                        dataSource={dataCboOTType}
                                        displayExpr={"OTTypeName"}
                                        valueExpr={"OTTypeID"}
                                        value={oTType.OTTypeID}
                                        label={Config.lang("Loai_tach_ca")}
                                        onChange={(e) => handleChangeForm("OTType", e)}
                                        clearAble
                                        viewType={'underlined'}
                                    />
                                </Col>}
                                <Col xs={6} sm={2}>
                                    <InputMask
                                        mask={'12:34'}
                                        maskChar={isLoadingForm ? "" : "_"}
                                        alwaysShowMask={true}
                                        formatChars={{
                                            '1': '[0-2]',
                                            '2': checkRuleOTFrom ? '[0-3]' : '[0-9]',
                                            '3': '[0-5]',
                                            '4': '[0-9]',
                                        }}
                                        style={{marginTop: "0.6rem"}}
                                        onBlur={() => {
                                            if (oTFrom) {
                                                let errMessage = "";
                                                const dataField = _.chain(oTFrom).split("")
                                                if (dataField.split('').filter((_, i) => i !== 2).every(val => val === "_").value()) {
                                                    errMessage = Config.lang('Truong_nay_bat_buoc_nhap')
                                                } else if (dataField.split('').some(val => val === "_").value()) {
                                                    errMessage = Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')
                                                }
                                                setErrors({...errors, OTFrom: errMessage})
                                            }
                                        }}
                                        disabled={isLoadingForm || modeForm === "view"}
                                        value={oTFrom}
                                        onChange={(e) => handleChangeForm("OTFrom", e,)}
                                    >
                                        {(props) => <TextField {...props}
                                                               label={Config.lang("Gio_tang_ca_tu")}
                                                               InputLabelProps={{shrink: true}}
                                                               margin={"normal"}
                                                               required={true}
                                                               variant={"standard"}/>}
                                    </InputMask>
                                    <FormHelperText
                                        style={{position: "absolute", top: 60, paddingRight: 15, paddingLeft: 15}}
                                        error={true}>{errors.OTFrom && errors.OTFrom}
                                    </FormHelperText>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <InputMask
                                        mask={'12:34'}
                                        maskChar={isLoadingForm ? "" : "_"}
                                        alwaysShowMask={true}
                                        formatChars={{
                                            '1': '[0-2]',
                                            '2': checkRuleOTTo ? '[0-3]' : '[0-9]',
                                            '3': '[0-5]',
                                            '4': '[0-9]',
                                        }}
                                        style={{marginTop: "0.6rem"}}
                                        onBlur={() => {
                                            if (oTTo) {
                                                let errMessage = "";
                                                const dataField = _.chain(oTTo).split("")
                                                if (dataField.split('').filter((_, i) => i !== 2).every(val => val === "_").value()) {
                                                    errMessage = Config.lang('Truong_nay_bat_buoc_nhap')
                                                } else if (dataField.split('').some(val => val === "_").value()) {
                                                    errMessage = Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')
                                                }
                                                setErrors({...errors, OTTo: errMessage})
                                            }
                                        }}
                                        disabled={isLoadingForm || modeForm === "view"}
                                        value={oTTo}
                                        onChange={(e) => handleChangeForm("OTTo", e)}
                                    >
                                        {(props) => <TextField {...props}
                                                               label={Config.lang("Gio_tang_ca_den")}
                                                               InputLabelProps={{shrink: true}}
                                                               margin={"normal"}
                                                               required={true}
                                                               variant={"standard"}/>}
                                    </InputMask>
                                    <FormHelperText
                                        style={{position: "absolute", top: 60, paddingRight: 15, paddingLeft: 15}}
                                        error={true}>{errors.OTTo && errors.OTTo}</FormHelperText>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <TextInput
                                        label={" "}
                                        className={classes.rmUnderLine}
                                        viewType={"underlined"}
                                        error={errors["OTTHour"] ?? errors["OTTHour"]}
                                        value={`${_.get(dataOtHour, "OTHour", 0)} h` || ""}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div style={{display: "flex", margin: "15px 0px"}}>
                                        {_.map(dataCaption, (item, i) => (<Checkbox
                                                key={i}
                                                style={{paddingRight: 15}}
                                                checked={!!dataForm[_.get(item, "RefID", "")]}
                                                onChange={(e) => handleChangeForm("Caption", e)}
                                                name={_.get(item, "RefID", "")}
                                                color="primary"
                                                label={_.get(item, "RefCaption", "")}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <TextInput
                                        disabled={isLoadingForm}
                                        value={reasonOT}
                                        readOnly={modeForm === "view"}
                                        label={Config.lang("Ly_do_tang_ca")}
                                        placeholder={Config.lang("Nhap")}
                                        onChange={e => handleChangeForm("ReasonOT", e)}
                                    />
                                </Col>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <FormGroup style={{marginBottom: 7}}>
                    <Accordion expand>
                        <AccordionSummary>
                            <div style={{marginRight: 15}}>
                                            <span
                                                className={isErrorDataGrid ? classes.dangerColor : ""}>{Config.lang("Danh_sach_nhan_vien")}
                                                <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span>
                                            </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {modeForm !== "view" &&
                            <FormGroup>
                                <Button
                                    label={Config.lang('Cap_nhat')}
                                    color={"primary"}
                                    viewType={"filled"}
                                    size={"medium"}
                                    disabled={
                                        _.size(dataGrid) < 1 ||
                                        isErrorDataGrid ||
                                        _.isEmpty(shiftID) ||
                                        modeForm === "view"
                                    }
                                    onClick={onUpdateGrid}
                                />
                            </FormGroup>}
                            <GridContainer
                                style={{border: "none", overflowX: "scroll"}}
                                dataSource={_.filter(dataGrid, item => _.get(item, "isNew", false) || _.get(item, "Deleted", 0) !== 1)}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                rowAlternationEnabled={false}
                                noDataText={Config.lang("No_data")}
                                loading={isLoadingGrid}
                                hoverStateEnabled={true}
                                height={isExpanded ? 270 : Config.getHeightGrid() - 217}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                            >
                                {Config.isMobile && (
                                    <Column
                                        width={80}
                                        alignment={"right"}
                                        allowEditing={false}
                                        cellRender={renderAction}
                                        allowSorting={false}
                                    />
                                )}
                                <Column cellRender={renderAvatar}/>
                                <Column caption={Config.lang("Ma_nhan_vien")} dataField={"EmployeeID"}/>
                                <Column caption={Config.lang("Ten_nhan_vien")} dataField={"EmployeeName"}/>
                                <Column
                                    alignment={"center"}
                                    caption={Config.lang("Ngay_di_ca")}
                                    cellRender={({data}) => formatDate(_.get(data, "AttendanceDate", ""))}/>
                                <Column
                                    width={200}
                                    caption={Config.lang("Ca_vao_ra")}
                                    dataField={"ShiftInOut"}/>
                                <Column alignment={"center"}
                                        caption={Config.lang("Tang_ca_tu")}
                                        dataField={"OTFrom"}
                                        cellRender={({data}) => {
                                            let oTFrom = _.get(data, "OTFrom", "");
                                            if(_.isNil(oTFrom)) return "__:__";
                                            if(_.includes(oTFrom, ":") && _.size(oTFrom) === 5) return oTFrom;
                                            return oTFrom.substring(0, 2) + ":" + oTFrom.substring(2, oTFrom.length);
                                        }}/>
                                />
                                <Column alignment={"center"}
                                        caption={Config.lang("Tang_ca_den")}
                                        dataField={"OTTo"}
                                        cellRender={({data}) => {
                                            let oTTo = _.get(data, "OTTo", "");
                                            if(_.isNil(oTTo)) return "__:__";
                                            if(_.includes(oTTo, ":") && _.size(oTTo) === 5) return oTTo;
                                            return oTTo.substring(0, 2) + ":" + oTTo.substring(2, oTTo.length);
                                        }}/>
                                <Column alignment={"center"} caption={Config.lang("Gio_tang_ca")}
                                        dataField={"OTHour"}/>
                                {_.map(dataCaption, (item, i) => <Column
                                    key={i}
                                    caption={_.get(item, "RefCaption", "")}
                                    dataField={_.get(item, "RefID", "")}
                                    dataType={"boolean"}
                                />)}
                                <Column alignment={"center"} caption={Config.lang("Loai_tach_ca")}
                                        dataField={"OTTypeName"}/>
                                <Column alignment={"center"} caption={Config.lang("Ghi_chu")}
                                        dataField={"Note"}/>
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowSorting={false}
                                    visible={!Config.isMobile}
                                    cellRender={renderAction}
                                />
                            </GridContainer>
                            <FormHelperText
                                style={{position: "unset", paddingLeft: 16}}
                                error={true}>{errors.dataGrid && errors.dataGrid}</FormHelperText>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <FormGroup style={{marginBottom: 34}}>
                    <Accordion>
                        <AccordionSummary>
                            <span>{Config.lang("Dinh_kem")}</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Attachments
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={isLoadingForm || isUploading || iPermission < 1 || isLoadingAttachment || modeForm === "view"}
                                maxLength={5}
                                uploading={isUploading}
                                onUploading={onUploading}
                                onChanged={onChangeAttachments}
                            />
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
            </ModalBody>
            {modeForm !== "view" && <ModalFooter>
                <Button label={Config.lang('Luu')}
                        startIcon={"SaveV2"}
                        color={"primary"}
                        viewType={"filled"}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        disabled={
                            isLoadingForm ||
                            isLoadingGrid ||
                            isLoadingOTType ||
                            isUploading ||
                            iPermission < 1 ||
                            isLoadingAttachment ||
                            isErrors ||
                            modeForm === "view" ||
                            isErrorDataGrid
                        }
                        onClick={onStore}
                />
            </ModalFooter>}
        </Modal>
    </>
};

export default compose(connect(
    (state) => ({
        getCboStatus: state.approvals.getCboStatus,
    }),
    dispatch => (
        {
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }
    )), withStyles(styles, {withTheme: true}))(W29F2061);
