/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/14/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as w29F2100Actions from "../../../../redux/W2X/W29F2100/W29F2100_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import CloseIcon from "@material-ui/icons/Close";

import "./W29F2100.scss";
import _ from "lodash";
import { Drawer, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import { LoadPanel } from "devextreme-react/load-panel";
const styles = (theme) => ({
    divIcon: {
        width: 30,
        height: 30,
    },
    headerToolbar: {
        height: 50,
        top: 0,
        width: "100%",
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        backgroundColor: "#EDEEF3",
        alignItems: "center",
    },
    closeButton: {
        alignSelf: "start",
        maxHeight: "calc(100% - 30px)",
    },
    bodyContainer: {
        backgroundColor: "#FFFFFF",
        height: "100%",
        width: "100%",
    },
    container: {

    },
    tabContent: {
        padding: "0px 24px",
        paddingTop: 20,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawer: {
        width: "40%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: "40%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        top: 50,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
    },
    text: {
        fontSize: 16,
        fontWeight: 500,
        color: "#151a30",
    },
    textBold: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#555868",
    },
    textTitle: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#7f828e",
    },
    titleNumber: {
        flexDirection: "row-reverse",
        display: "flex",
        color: "#0095ff",
        fontSize: "1rem",
        fontWeight: 700,
    }
});
class W29F2100PopupDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            obj: {},
            arrInfo: [],
            arrShift: [],
            loadDetailWorkDay: false,
            dataDetailWorkDay: []
        };
        this.totalRef = null;
    }
    onCloseModal = () => {
        const { onClose } = this.props;
        this.setState({
            obj: {},
            arrInfo: [],
            arrShift: [],
            dataDetailWorkDay: []
        });
        if (onClose) onClose();
    };
    handleChange = (e, value) => {
        this.setState({ indexTab: value });
    };
    checkIsNull = (str, key) => {
        if (str !== null && str !== "" && str !== undefined) {
            return str + key;
        }
        return "";
    };
    loadDetail = () => {
        const { dateClick, EmployeeID } = this.props;
        this.setState({ loadDetailWorkDay: true });
        if (Config.isEmpty(dateClick)) return null;
        const param = {
            AttendanceDate: dateClick.dateStr,
            EmployeeID,
            Language: Config.language || "84",
        };
        this.props.w29F2100Actions.getDetailWorkday(param, (error, data) => {
            this.setState({ loadDetailWorkDay: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.loadData(data);
            }
        });
    };
    loadData = (data) => {
        if (data.length > 0) {
            let obj = {
                title: `${data[0].EmployeeName} - ${data[0].AttendanceDate}`,
                TotalOriNormal: data[0].TotalOriNormal || "",
                TotalLeave: data[0].TotalLeave || "",
            };
            obj.info = {
                AttendanceCardNo: data[0].AttendanceCardNo || "",
                DutyName: data[0].DutyName || "",
                DepartmentName: data[0].DepartmentName || "",
                ProjectName: data[0].ProjectName || "",
            };
            obj.shifts = data.map((item) => {
                return {
                    ShiftName: item.ShiftName || "",
                    ShiftID: item.ShiftID || "",
                    TimeWork: item.TimeWork || "",
                    DateOn1:
                        (item.DateOn1 && Config.convertDate(item.DateOn1)) || "",
                    TimeOn1: item.TimeOn1 || "",
                    DateOff1:
                        (item.DateOff1 && Config.convertDate(item.DateOff1)) || "",
                    TimeOff1: item.TimeOff1 || "",
                    DateOn2:
                        (item.DateOn2 && Config.convertDate(item.DateOn2)) || "",
                    TimeOn2: item.TimeOn2 || "",
                    DateOff2:
                        (item.DateOff2 && Config.convertDate(item.DateOff2)) || "",
                    TimeOff2: item.TimeOff2 || "",
                    DateOn3:
                        (item.DateOn3 && Config.convertDate(item.DateOn3)) || "",
                    TimeOn3: item.TimeOn3 || "",
                    DateOff3:
                        (item.DateOff3 && Config.convertDate(item.DateOff3)) || "",
                    TimeOff3: item.TimeOff3 || "",
                    DateOn4:
                        (item.DateOn4 && Config.convertDate(item.DateOn4)) || "",
                    TimeOn4: item.TimeOn4 || "",
                    DateOff4:
                        (item.DateOff4 && Config.convertDate(item.DateOff4)) || "",
                    TimeOff4: item.TimeOff4 || "",
                    DateOn5:
                        (item.DateOn5 && Config.convertDate(item.DateOn5)) || "",
                    TimeOn5: item.TimeOn5 || "",
                    DateOff5:
                        (item.DateOff5 && Config.convertDate(item.DateOff5)) || "",
                    TimeOff5: item.TimeOff5 || "",
                    // Time1: item.Time1 || "",
                    // Time2: item.Time2 || "",
                    // Time3: item.Time3 || "",
                    // Time4: item.Time4 || "",
                    // Time5: item.Time5 || "",
                    NormalHours: item.NormalHours || "",
                    OTHours: item.OTHours || "",
                };
            });
            let arrInfo = [
                {
                    title: Config.lang("DHR_Ma_the_cham_cong"),
                    value: obj.info.AttendanceCardNo,
                },
                {
                    title: Config.lang("DHR_Chuc_vu"),
                    value: obj.info.DutyName,
                },
                {
                    title: Config.lang("DHR_Phong_ban"),
                    value: obj.info.DepartmentName,
                },
                {
                    title: Config.lang("DHR_Du_an"),
                    value: obj.info.ProjectName,
                },
            ];

            let arrShift = [];
            obj.shifts.map((item) => {
                arrShift.push([
                    {
                        title: Config.lang("DHR_Ten_ca"),
                        value: item.ShiftName,
                    },
                    {
                        title: Config.lang("DHR_Ma_ca"),
                        value: item.ShiftID,
                    },
                    {
                        title: Config.lang("DHR_Thoi_gian"),
                        value: item.TimeWork,
                    },
                    {
                        title: Config.lang("DHR_Ngay_chot_van_tay"),
                        Time1: `${this.checkIsNull(item.DateOn1, ",")} ${this.checkIsNull(
                            item.TimeOn1,
                            ";"
                        )} ${this.checkIsNull(item.DateOff1, ",")} ${item.TimeOff1}`,
                        Time2: `${this.checkIsNull(item.DateOn2, ",")} ${this.checkIsNull(
                            item.TimeOn2,
                            ";"
                        )} ${this.checkIsNull(item.DateOff2, ",")} ${item.TimeOff2}`,
                        Time3: `${this.checkIsNull(item.DateOn3, ",")} ${this.checkIsNull(
                            item.TimeOn3,
                            ";"
                        )} ${this.checkIsNull(item.DateOff3, ",")} ${item.TimeOff3}`,
                        Time4: `${this.checkIsNull(item.DateOn4, ",")} ${this.checkIsNull(
                            item.TimeOn4,
                            ";"
                        )} ${this.checkIsNull(item.DateOff4, ",")} ${item.TimeOff4}`,
                        Time5: `${this.checkIsNull(item.DateOn5, ",")} ${this.checkIsNull(
                            item.TimeOn5,
                            ";"
                        )} ${this.checkIsNull(item.DateOff5, ",")} ${item.TimeOff5}`,
                    },
                    {
                        title: Config.lang("DHR_So_gio"),
                        value: item.NormalHours,
                    },
                    {
                        title: Config.lang("DHR_So_gio_tang_ca"),
                        value: item.OTHours,
                    },
                ]);
                return item;
            });
            this.setState({ obj, arrInfo, arrShift });
        }
    };

    // componentDidMount() {
    //     this.loadDetail();
    // }
    componentDidUpdate(prevProps) {
        if (prevProps.showW29F2100 !== this.props.showW29F2100) {
            this.loadDetail();
        }
    }
    render() {
        const { indexTab, obj, arrInfo, arrShift, loadDetailWorkDay } = this.state;
        let { classes, showW29F2100 } = this.props;
        return (
            <React.Fragment>

                <Drawer
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    // variant={"persistent"}
                    anchor="right"
                    open={showW29F2100}
                    onClose={this.onCloseModal}
                    ModalProps={{
                        BackdropProps: {
                            invisible: true,
                        },
                    }}
                    id="#W29F2100-popup-detail"
                >
                    {/* <div
                        className={"W29F2100-detail-container " + classes.container}
                    > */}
                    
                    <div className={classes.headerToolbar}>
                        <Typography variant="h6">{obj.title}</Typography>

                        <IconButton
                            aria-label="close"
                            className={classes.divIcon}
                            onClick={this.onCloseModal}
                            size={"medium"}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div id="bodyContainer" className={classes.bodyContainer}>
                        <Tabs
                            value={indexTab}
                            onChange={(e, value) => this.handleChange(e, value)}
                            // textColor="primary"
                            indicatorColor={"primary"}
                            textColor={"primary"}
                            style={{ fontSize: "16px" }}
                        >
                            <Tab label={Config.lang("DHR_Tong_hop")} />
                            <Tab label={Config.lang("DHR_Don_tu")} disabled />
                        </Tabs>
                        <TabContent
                            style={{ padding: "10px 0" }}
                            className={classes.tabContent}
                            activeKey={indexTab}
                            disableSwipe={true}
                            lazyLoading={true}
                        >
                            <TabPanel index={0}>
                                <div style={{ overflow: "auto", height: "75vh" }} >
                                    <div style={{ display: "table" }}>
                                        <div style={{ display: "table-row" }}>
                                            <div style={{ display: "table-cell" }} className={classes.text}>
                                                {Config.lang("DHR_Cong_hanh_chinh_trong_ngay")}:
                                            </div>
                                            <div
                                                style={{ display: "table-cell" }}
                                                className={`${classes.titleNumber} pdl10`}
                                            >
                                                {obj?.TotalOriNormal}
                                            </div>
                                        </div>
                                        <div style={{ display: "table-row" }}>
                                            <div
                                                style={{ display: "table-cell" }}
                                                className={`${classes.text} pdb15`}
                                            >
                                                {Config.lang("DHR_Nghi_phep_trong_ngay")}:
                                            </div>
                                            <div
                                                style={{ display: "table-cell" }}
                                                className={`${classes.titleNumber} pdl10`}
                                            >
                                                {obj?.TotalLeave}
                                            </div>
                                        </div>
                                        {/* ------------------ title -------------------*/}
                                        <div style={{ display: "table-row" }}>
                                            <div
                                                style={{ display: "table-cell" }}
                                                className={`pdt10 pdb5 ${classes.textBold}`}
                                            >
                                                {Config.lang("DHR_Thong_tin_nhan_su")}
                                            </div>
                                        </div>
                                        {/* ------------------ title -------------------*/}
                                        {arrInfo.length > 0 && arrInfo.map((item, index) => {
                                            return (
                                                <div style={{ display: "table-row" }} key={index}>
                                                    <div
                                                        style={{ display: "table-cell" }}
                                                        className={`${classes.textTitle} pdt5 pdb5`}
                                                    >
                                                        {item.title}:
                                                        </div>
                                                    <div
                                                        style={{ display: "table-cell" }}
                                                        className={`${classes.text} pdl10`}
                                                    >
                                                        {item.value}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {/* ------------------ title -------------------*/}

                                        {/* ------------------ title -------------------*/}
                                        {arrShift.length > 0 && arrShift.map((val, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <div style={{ display: "table-row" }} key={idx}>
                                                        <div
                                                            style={{ display: "table-cell" }}
                                                            className={`pdt15 pdb5 ${classes.textBold}`}
                                                        >
                                                            {Config.lang("DHR_Ca_lam_viec")}
                                                        </div>
                                                    </div>
                                                    {val.map((item, index) => {
                                                        return (
                                                            <div style={{ display: "table-row" }} key={index}>
                                                                <div
                                                                    style={{ display: "table-cell" }}
                                                                    className={`${classes.textTitle} pdt5 pdb5`}
                                                                >
                                                                    {item.title}:
                                                                </div>
                                                                {_.isUndefined(item.value) ? (
                                                                    <div
                                                                        style={{ display: "table-cell" }}
                                                                        className={`${classes.text} pdl10`}
                                                                    >
                                                                        <div>{item.Time1}</div>
                                                                        <div>{item.Time2}</div>
                                                                        <div>{item.Time3}</div>
                                                                        <div>{item.Time4}</div>
                                                                        <div>{item.Time5}</div>
                                                                    </div>
                                                                ) : (
                                                                        <div
                                                                            style={{ display: "table-cell" }}
                                                                            className={`${classes.text} pdl10`}
                                                                        >
                                                                            {item.value}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                                <LoadPanel
                                    position={{
                                        my: "center",
                                        of: "#bodyContainer",
                                    }}
                                    visible={showW29F2100 && loadDetailWorkDay}
                                    showIndicator={true}
                                    shading={false}
                                    showPane={true}
                                    container={"#W29F2100-popup-detail"}
                                />
                            </TabPanel>
                            <TabPanel index={1}>
                                <div>asjdoasjoajsodjasd</div>
                            </TabPanel>
                        </TabContent>
                    </div>
                    {/* </div> */}
                </Drawer>

            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        (state) => ({}),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29F2100Actions: bindActionCreators(w29F2100Actions, dispatch),
        })
    ),
    withStyles(styles, { withTheme: true })
)(W29F2100PopupDetail);
