/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/14/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as w29F2100Actions from "../../../../redux/W2X/W29F2100/W29F2100_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "./W29F2100.scss";
import { Combo } from "../../../common/form-material";
import Icons from "../../../common/icons";
import { IconButton } from "@material-ui/core";
import _ from "lodash";
import W29F2100PopupDetail from "./W29F2100PopupDetail";
import { LoadPanel } from "devextreme-react/load-panel";

const styles = (theme) => ({
    divIcon: {
        width: 24,
        height: 24,
        backgroundColor: "#111D5E",
    },
    divHeadContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    divCombo: {
        display: "flex",
        flexDirection: "row",
    },
    divEnd: {
        justifyContent: "flex-end",
        display: "flex",
    },
    cbEmployees: {
        width: "45%",
        marginRight: 10,
        marginTop: 11.5,
    },
    divText: {
        fontSize: 14,
        fontWeight: 500,
        color: "#7F828E",
    },
    divNote: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
    },
    titleNumber: {
        flexDirection: "row-reverse",
        display: "flex",
        color: "#0095ff",
        fontSize: "1rem",
        fontWeight: 700,
    },
    title: {
        fontSize: "0.75rem",
        fontWeight: 700,
        lineHeight: "14px",
        color: "#555868",
        paddingRight: "5px",
    },
    text: {
        fontSize: 16,
        fontWeight: 500,
        color: "#151a30",
    },
});
class W29F2100 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermissions: {},
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            calendar: {
                calendarWeekends: true,
                calendarEvents: [
                    // initial event data
                ],
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            error: null,
            loadEmployees: false,
            employees: {
                EmployeeID: "",
            },
            MinDate: null,
            MaxDate: null,
            Period: {},
            cboPeriod: [],
            dataNote: [],
            totalAtt: 0,
            totalLeave: 0,
            showW29F2100: false,
            currentMonth: "",
            loadPeriod: false,
            disableCboEmployee: false,
            disabledLeft: false,
            disabledRight: false,
            dateClick: {},
        };
        this.calendarComponentRef = React.createRef();
        this.filterCboEmployees = {
            skip: 0,
            limit: 50,
            strSearch: "",
        };
        this.Period = {
            TranMonth: "",
            TranYear: "",
        };
        this.clicking = false;
        this.calendarLoading = null;
        this.clicked = false;
        //grab and sroll
        this.el = null;
        this.isDown = false;
        this.startX = null;
        this.scrollLeft = null;
    }

    toggleWeekends = () => {
        this.setState({
            // update a property
            ...this.state.calendar,
            calendarWeekends: !this.state.calendar.calendarWeekends,
        });
    };
    goPrevMonth = async () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        const { cboPeriod, Period, MinDate } = this.state;
        let checkCbMin = cboPeriod.indexOf(Period) === cboPeriod.length - 1;
        if (
            calendarApi.currentDataManager.data.currentDate.getMonth() >
            new Date(MinDate).getMonth()
        ) {
            await calendarApi.prev();
            let currentMonth =
                calendarApi &&
                this.calendarComponentRef.current.getApi().currentDataManager.data
                    .viewTitle;
            this.setState({ currentMonth });
            if (
                checkCbMin &&
                calendarApi.currentDataManager.data.currentDate.getMonth() ===
                new Date(MinDate).getMonth()
            ) {
                this.setState({ disabledLeft: true });
            } else {
                this.setState({ disabledRight: false });
            }
        } else {
            if (cboPeriod.length > 0) {
                let goPrev = cboPeriod[cboPeriod.indexOf(Period) + 1];
                this.handleChange("PeriodTime", { value: goPrev.PeriodTime });
            }
        }
    };
    goNextMonth = async () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        const { cboPeriod, Period, MaxDate } = this.state;
        let checkCbMax = cboPeriod.indexOf(Period) === 0;
        if (
            calendarApi.currentDataManager.data.currentDate.getMonth() <
            new Date(MaxDate).getMonth()
        ) {
            await calendarApi.next();
            let currentMonth =
                calendarApi &&
                this.calendarComponentRef.current.getApi().currentDataManager.data
                    .viewTitle;
            this.setState({ currentMonth });
            if (
                checkCbMax &&
                calendarApi.currentDataManager.data.currentDate.getMonth() ===
                new Date(MaxDate).getMonth()
            ) {
                this.setState({ disabledRight: true });
            } else {
                this.setState({ disabledLeft: false });
            }
        } else {
            if (cboPeriod.length > 0 && cboPeriod.indexOf(Period) !== 0) {
                let goNext = cboPeriod[cboPeriod.indexOf(Period) - 1];
                this.handleChange("PeriodTime", { value: goNext.PeriodTime });
            }
        }
    };
    getInfo = () => {
        return {
            EmployeeID: Config.getHREmployeeID(),
        };
    };
    //Grab and scroll
    mousedown = (e) => {
        this.isDown = true;
        // this.el.classList.add('active');
        this.startX = e.pageX - this.el.offsetLeft;
        this.scrollLeft = this.el.scrollLeft;
        this.el.style.cursor = "grabbing";
        this.el.style.userSelect = "none";
    };
    mouseleave = () => {
        this.isDown = false;
        // this.el.classList.remove('active');
        this.el.style.cursor = "grab";
        this.el.style.removeProperty("user-select");
    };

    mouseup = () => {
        this.isDown = false;
        // this.el.classList.remove('active');
        this.el.style.cursor = "grab";
        this.el.style.removeProperty("user-select");
    };
    mousemove = (e) => {
        let isDown = this.isDown,
            scrollLeft = this.scrollLeft,
            startX = this.startX;
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - this.el.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        this.el.scrollLeft = scrollLeft - walk;
        // console.log(walk);
    };
    componentDidMount = async () => {
        await this.loadPermission();
        await this.loadDefaultEmployee();
        this.loadCboEmployees();
        this.loadCboPeriod();
        this.loadNotes();
        if (this.el && !Config.isMobile) {
            this.el.addEventListener("mousedown", (e) => {
                this.mousedown(e);
            });
            this.el.addEventListener("mouseleave", () => {
                this.mouseleave();
            });
            this.el.addEventListener("mouseup", () => {
                this.mouseup();
            });
            this.el.addEventListener("mousemove", (e) => {
                this.mousemove(e);
            });
        }
    };
    componentWillUnmount() {
        if (this.el && !Config.isMobile) {
            this.el.removeEventListener("mousedown", (e) => {
                this.mousedown(e);
            });
            this.el.removeEventListener("mouseleave", () => {
                this.mouseleave();
            });
            this.el.removeEventListener("mouseup", () => {
                this.mouseup();
            });
            this.el.removeEventListener("mousemove", (e) => {
                this.mousemove(e);
            });
        }
    }

    loadDateByTrans = (TranMonth, TranYear) => {
        const params = {
            TranMonth,
            TranYear,
        };
        this.props.generalActions.getDateByTrans(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState(
                    {
                        MinDate: null,
                        MaxDate: null,
                    },
                    () => {
                        this.loadMonthPayroll(TranMonth, TranYear, data);
                    }
                );
            }
        });
    };
    loadCboPeriod = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
        };
        this.setState({ loadPeriod: true });
        this.props.generalActions.getCboPeriod(params, (errors, data) => {
            this.setState({ loadPeriod: false });
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                let obj = {
                    _TranMonth: Config.getHRTransMonth(),
                    _TranYear: Config.getHRTransYear(),
                };
                const Period = data.find(
                    (d) =>
                        Number(d.TranMonth) === Number(obj._TranMonth) &&
                        Number(d.TranYear) === Number(obj._TranYear)
                );
                this.setState({ cboPeriod: data, Period }, () => {
                    this.loadDateByTrans(obj._TranMonth, obj._TranYear);
                });
            }
        });
    };
    loadPermission = async () => {
        await this.props.generalActions.getPermission(
            ["W29F2101"],
            (arrPer) => {
                if (arrPer !== 0) {
                    let iPermissions = {};
                    arrPer.forEach((per) => {
                        if (
                            per.hasOwnProperty("FormID") &&
                            per.hasOwnProperty("IsPermission")
                        ) {
                            iPermissions[per.FormID] = per.IsPermission;
                        }
                    });
                    this.setState({ iPermissions });
                } else {
                    this.setState({
                        iPermissions: {
                            W29F2101: 0,
                        },
                    });
                }
            },
            true
        );
    };
    loadDefaultEmployee = async () => {
        const { EmployeeID } = this.getInfo();
        let loadDefault = await Config.getUser({ EmployeeID });
        this.setState({
            employees: loadDefault,
            dataCboEmployees: {
                rows: this.state.dataCboEmployees.rows.concat([loadDefault]),
            },
        });
    };
    loadCboEmployees = (isReset) => {
        const param = {
            FormID: "W29F2101",
            Mode: 0,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ loadEmployees: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ loadEmployees: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : dataCboEmployees.rows;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };
    loadMonthPayroll = (TranMonth, TranYear, minMaxDate) => {
        // let minMaxDate = {
        //     MinDate: "2020-10-01",
        //     MaxDate: "2020-12-15",
        // }
        const { EmployeeID } = this.getInfo();
        const param = {
            TranMonth,
            TranYear,
            Language: Config.language || "84",
            EmployeeID:
                (this.state.employees && this.state.employees.EmployeeID) || EmployeeID,
        };
        this.setState({ loadMonthPayroll: true });
        this.props.w29F2100Actions.getMonthPayroll(param, (error, data) => {
            this.setState({ loadMonthPayroll: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                let temp = [];
                const groupData = _.groupBy(data, "Date");
                for (let key in groupData) {
                    const obj = {};
                    obj.start = key;
                    const attArr = groupData[key]
                        .filter((i) => i.AttLeaType === "ATT")
                        .map((item) => item.Amount + " " + item.Type)
                        .join("; ");
                    const leaveArr = groupData[key]
                        .filter((i) => i.AttLeaType === "LEAVE")
                        .map((item) => item.Amount + " " + item.Type)
                        .join("; ");
                    obj.att = attArr;
                    obj.leave = leaveArr;
                    groupData[key].map((item) => {
                        obj.extendedProps = item;
                        return item;
                    });
                    obj.dateStr = moment(key).format("YYYY-MM-DD");
                    temp.push(obj);
                }
                const att = data
                    .filter((item) => item.AttLeaType === "ATT")
                    .reduce((a, b) => a + b.Amount, 0);
                const leave = data
                    .filter((item) => item.AttLeaType === "LEAVE")
                    .reduce((a, b) => a + b.Amount, 0);
                this.setState(
                    {
                        // add new event data
                        MinDate: minMaxDate.MinDate,
                        MaxDate: minMaxDate.MaxDate,
                        // allDay: arg.allDay,
                    },
                    () => {
                        const { cboPeriod, Period } = this.state;
                        let check =
                            this.calendarComponentRef.current &&
                            this.calendarComponentRef.current.getApi();
                        let currentMonth =
                            check &&
                            this.calendarComponentRef.current.getApi().currentDataManager
                                .data;
                        let checkMax =
                            currentMonth.currentDate.getMonth() <
                            new Date(minMaxDate.MaxDate).getMonth();
                        let checkCbMax = cboPeriod.indexOf(Period) === 0;
                        let checkMin =
                            currentMonth.currentDate.getMonth() >
                            new Date(minMaxDate.MinDate).getMonth();
                        let checkCbMin = cboPeriod.indexOf(Period) === cboPeriod.length - 1;
                        this.setState({
                            calendar: { calendarEvents: temp, calendarWeekends: true },
                            totalAtt: att,
                            totalLeave: leave,
                            currentMonth: currentMonth.viewTitle,
                            disabledRight: checkCbMax
                                ? checkMax
                                    ? !checkMax
                                    : checkCbMax
                                : false,
                            disabledLeft: checkCbMin
                                ? checkMin
                                    ? !checkMin
                                    : checkCbMin
                                : false,
                            dateClick: {},
                            showW29F2100: false,
                        });
                    }
                );
            }
        });
    };
    loadNotes = () => {
        const param = {
            Language: Config.language || "84",
        };
        this.props.w29F2100Actions.getNote(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    dataNote: data,
                });
            }
        });
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        switch (key) {
            case "EmployeeID": {
                const { Period } = this.state;
                this._setemployees(key, e.value, () =>
                    this.loadDateByTrans(Period.TranMonth, Period.TranYear)
                );
                break;
            }
            case "PeriodTime": {
                const { cboPeriod } = this.state;
                const Period = cboPeriod.find((d) => d.PeriodTime === e.value);
                if (!Config.isEmpty(Period)) {
                    this.setState({ Period });
                    this.loadDateByTrans(Period.TranMonth, Period.TranYear);
                }
                break;
            }
            default:
                break;
        }
    };

    _setemployees = (key, value, cb) => {
        if (!key) return false;
        this.setState(
            {
                employees: { ...value },
            },
            () => {
                if (cb) cb();
            }
        );
    };

    handleDateClick = (e) => {
        let img = document.querySelector(`[date="${e.date}"]`);
        if (img && this.clicked && Config.isEmpty(this.state.dateClick)) {
            this.setState({ showW29F2100: true, dateClick: e });
        }
    };
    onClose = () => {
        const { dateClick } = this.state;
        this.setState({ showW29F2100: false, dateClick: {} });
        if (this.clicking) {
            let img = document.querySelector(`[date="${dateClick.date}"]`);
            this.clicking.classList.remove("bgColor");
            this.clicking = null;
            this.clicked = false;
            img.src = require("./group_view.svg");
            img.style.display = "none";
        }
    };
    render() {
        let {
            dataCboEmployees,
            error,
            loadEmployees,
            employees,
            cboPeriod,
            Period,
            dataNote,
            MinDate,
            MaxDate,
            showW29F2100,
            totalAtt,
            totalLeave,
            currentMonth,
            loadMonthPayroll,
            loadPeriod,
            disabledLeft,
            disabledRight,
            dateClick,
            iPermissions,
        } = this.state;
        const { EmployeeID } = this.getInfo();
        let { classes, theme } = this.props;
        // console.log(this.state.dateFrom, this.state.dateTo, this.state.MaxDate, this.state.MinDate);
        // console.log(this.calendarComponentRef.current && this.calendarComponentRef.current.getApi() && this.calendarComponentRef.current.getApi().currentDataManager.data.viewTitle);
        const disabledCboEmployee = iPermissions && iPermissions.W29F2101 < 2;
        // if (!iPermission) return null;
        return (
            <React.Fragment>
                <ActionToolbar
                    title={Config.lang("DHR_Bang_cong_thang")}
                    style={{ height: 55 }}
                />
                <W29F2100PopupDetail
                    showW29F2100={showW29F2100}
                    onClose={this.onClose}
                    dateClick={dateClick}
                    EmployeeID={(employees && employees.EmployeeID) || EmployeeID}
                />
                <div style={{ width: showW29F2100 ? "calc(60% - 50px)" : "100%" }}>
                    <Row className="display_row align-center flex-wrap ">
                        <Col
                            lg={showW29F2100 ? 9 : 6}
                            md={showW29F2100 ? 9 : 5}
                            sm={8}
                            xs={8}
                        >
                            <Combo.Employees
                                dataSource={dataCboEmployees || []}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                acceptCustomValue={true}
                                error={error && error["EmployeeID"]}
                                displayExpr={"EmployeeName"}
                                keyExpr={"EmployeeID"}
                                // className={classes.cbEmployees}
                                valueExpr={"EmployeeID"}
                                subTextExpr={"EmployeeID"}
                                value={employees && employees.EmployeeID}
                                stylingMode={"underlined"}
                                loading={loadEmployees}
                                disabled={disabledCboEmployee}
                                shrink={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                isHover={true}
                                onValueChanged={(e) => this.handleChange("EmployeeID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.filterCboEmployees.skip = 0;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                        <Col
                            lg={showW29F2100 ? 3 : 2}
                            md={showW29F2100 ? 3 : 2}
                            sm={4}
                            xs={4}
                        >
                            <Combo
                                dataSource={cboPeriod || []}
                                displayExpr={"PeriodTime"}
                                valueExpr={"PeriodTime"}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                loading={loadPeriod}
                                // disabled={loading || mode === 'view'}
                                value={Period && Period.PeriodTime}
                                shrink={true}
                                label={Config.lang("DHR_Ky_cong")}
                                // style={{ width: "25%", marginLeft: 10, marginRight: 10 }}
                                onValueChanged={(e) => this.handleChange("PeriodTime", e)}
                            />
                        </Col>
                        <Col
                            lg={showW29F2100 ? 12 : 4}
                            md={showW29F2100 ? 12 : 5}
                            sm={12}
                            xs={12}
                            className="display_row jus-between"
                        >
                            <div
                                className="pdb5 display_row align-right"
                                style={{ width: 200 }}
                            >
                                <span className="pdr15">
                                    <span className={classes.title}>
                                        {Config.lang("DHR_Ngay_bat_dau")}
                                    </span>
                                    <span className={classes.titleNumber}>
                                        {(MinDate && moment(MinDate).format("DD/MM/YYYY")) || ""}
                                    </span>
                                </span>
                                <span>
                                    <span className={classes.title}>
                                        {Config.lang("DHR_Ngay_ket_thuc")}
                                    </span>
                                    <span className={classes.titleNumber}>
                                        {(MaxDate && moment(MaxDate).format("DD/MM/YYYY")) || ""}
                                    </span>
                                </span>
                            </div>
                            <table style={{ minWidth: 100 }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className={classes.title}>
                                                {Config.lang("DHR_Tong_so_cong")}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={classes.titleNumber}>{totalAtt}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={classes.title}>
                                                {Config.lang("DHR_Tong_so_phep")}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={classes.titleNumber}>{totalLeave}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {/* </div> */}
                </div>
                <Row
                    style={{
                        height: "85%",
                        width: showW29F2100 ? "calc(60% - 50px)" : "100%",
                        margin: 0,
                    }}
                >
                    <Col lg={10} md={10} sm={12} style={{ height: "100%" }}>
                        <div
                            style={{
                                height: "100%",
                                overflow: "auto",
                            }}
                            ref={(ref) => (this.el = ref)}
                        >
                            <div className="demo-app-calendar">
                                {MinDate && (
                                    <FullCalendar
                                        timeZone="UTC"
                                        headerToolbar={false}
                                        titleFormat={{ year: "numeric", month: "numeric" }}
                                        // eventBackgroundColor="black"
                                        // eventBorderColor="yellow"
                                        // eventTextColor="yellow"
                                        height={"100%"}
                                        locale={Config.language === "84" ? "vi" : "en"}
                                        initialDate={moment(MinDate).format("YYYY-MM-DD")}
                                        eventContent={(arg) => {
                                            const event = arg.event.toPlainObject();
                                            const { att, leave } = event.extendedProps;
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "100%",
                                                        whiteSpace: "pre-wrap",
                                                        textAlign: "center",
                                                        top: 0,
                                                    }}
                                                >
                                                    <div style={{ display: "table" }}>
                                                        <div style={{ display: "table-row" }}>
                                                            <div style={{ display: "table-cell" }}>
                                                                {att || ""}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "table-row" }}>
                                                            <div style={{ display: "table-cell" }}>
                                                                {leave || ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        dayCellDidMount={(evt) => {
                                            const tdDay = evt.el.closest(".fc-daygrid-day");
                                            const dateTop = tdDay.querySelector(
                                                ".fc-daygrid-day-top"
                                            );
                                            const mainContainer = document.createElement("div");
                                            mainContainer.setAttribute("data-id", evt.date);
                                            dateTop.append(mainContainer);
                                        }}
                                        eventDidMount={(evt) => {
                                            const event = evt.event.toPlainObject();
                                            const tdDay = evt.el.closest(".fc-daygrid-day");
                                            const tdDayNumber = tdDay.querySelector(
                                                ".fc-daygrid-day-top .fc-daygrid-day-number"
                                            );
                                            const tdDayEvent = tdDay.querySelector(
                                                ".fc-daygrid-event"
                                            );
                                            const bgColorByWorkDayType = (event.extendedProps && event.extendedProps.WorkDayType === "NADate")
                                                ? "#FFFFFF"
                                                : event.extendedProps.ColorCode;
                                            const bgColor = event.backgroundColor
                                                ? event.backgroundColor
                                                : event.extendedProps
                                                    ? bgColorByWorkDayType
                                                    : "";
                                            let newEL = document.createElement("img");
                                            newEL.style.display = "none";
                                            if (tdDay && tdDayNumber) {
                                                tdDay.style.backgroundColor = bgColor;
                                                tdDayNumber.style.color = theme.palette.getContrastText(
                                                    bgColor
                                                );
                                                tdDayEvent.style.color = theme.palette.getContrastText(
                                                    bgColor
                                                );
                                                tdDay.addEventListener("mouseenter", (e) => {
                                                    e.target.style.backgroundColor = "#DFEFFF";
                                                    if (_.isEmpty(this.clicking)) {
                                                        newEL.style.display = "block";
                                                    }
                                                });
                                                tdDay.addEventListener("mouseleave", (e) => {
                                                    e.target.style.backgroundColor = bgColor;
                                                    if (_.isEmpty(this.clicking)) {
                                                        newEL.style.display = "none";
                                                    }
                                                });
                                                newEL.onclick = (e) => {
                                                    this.clicked = true;
                                                    if (_.isEmpty(this.clicking)) {
                                                        e.target.src = require("./group_view_hover.svg");
                                                    }
                                                    tdDay.addEventListener("click", (e) => {
                                                        if (this.clicked) {
                                                            e.preventDefault();
                                                            if (_.isEmpty(this.clicking)) {
                                                                tdDay.classList.add("bgColor");
                                                                this.clicking = tdDay;
                                                            }
                                                        }
                                                    });
                                                };
                                            }
                                            //add icon
                                            let date = new Date(event.start);
                                            let parent = document.querySelector(
                                                `[data-id="${date}"]`
                                            );
                                            newEL.setAttribute("date", date);
                                            newEL.src = require("./group_view.svg");
                                            newEL.addEventListener("mouseenter", (e) => {
                                                if (!this.clicked) {
                                                    e.target.src = require("./group_view_hover.svg");
                                                }
                                            });
                                            newEL.addEventListener("mouseleave", (e) => {
                                                if (!this.clicked) {
                                                    e.target.src = require("./group_view.svg");
                                                }
                                            });
                                            let findNewEL = document.querySelector(
                                                `[date="${date}"]`
                                            );
                                            if (
                                                _.isNull(findNewEL) &&
                                                event.extendedProps.AmountType
                                            ) {
                                                parent.appendChild(newEL);
                                            }
                                        }}
                                        eventWillUnmount={(evt) => {
                                            const event = evt.event.toPlainObject();
                                            const tdDay = evt.el.closest(".fc-daygrid-day");
                                            const tdDayNumber = tdDay.querySelector(
                                                ".fc-daygrid-day-top .fc-daygrid-day-number"
                                            );
                                            const bgColorByWorkDayType = (event.extendedProps && event.extendedProps.WorkDayType === "NADate")
                                                ? "#FFFFFF"
                                                : event.extendedProps.ColorCode;
                                            const bgColor = event.backgroundColor
                                                ? event.backgroundColor
                                                : event.extendedProps
                                                    ? bgColorByWorkDayType
                                                    : "";
                                            let date = new Date(event.start);
                                            let newEL = document.querySelector(`[date="${date}"]`);
                                            if (tdDay && tdDayNumber) {
                                                tdDay.removeEventListener("mouseenter", (e) => {
                                                    e.target.style.backgroundColor = "#DFEFFF";
                                                });
                                                tdDay.removeEventListener("mouseleave", (e) => {
                                                    e.target.style.backgroundColor = bgColor;
                                                });
                                                newEL.onclick = (e) => {
                                                    this.clicked = true;
                                                    e.target.src = require("./group_view_hover.svg");
                                                    tdDay.removeEventListener("click", (e) => {
                                                        if (this.clicked) {
                                                            e.preventDefault();
                                                            if (_.isEmpty(this.clicking)) {
                                                                tdDay.classList.add("bgColor");
                                                                this.clicking = tdDay;
                                                            }
                                                        }
                                                    });
                                                };
                                            }
                                            //add icon
                                            newEL.removeEventListener("mouseenter", (e) => {
                                                if (!this.clicked) {
                                                    e.target.src = require("./group_view_hover.svg");
                                                }
                                            });
                                            newEL.removeEventListener("mouseleave", (e) => {
                                                if (!this.clicked) {
                                                    e.target.src = require("./group_view.svg");
                                                }
                                            });
                                        }}
                                        firstDay="1"
                                        visibleRange={{
                                            start: MinDate,
                                            end: MaxDate,
                                        }}
                                        // validRange={{
                                        //     start: moment(MinDate).format("YYYY-MM-DD"),
                                        //     end: moment(MaxDate).format("YYYY-MM-DD")
                                        // }}
                                        plugins={[
                                            dayGridPlugin,
                                            // timeGridPlugin,
                                            interactionPlugin,
                                            // listPlugin,
                                        ]}
                                        dayHeaderFormat={{ weekday: "long" }}
                                        displayEventEnd
                                        ref={this.calendarComponentRef}
                                        weekends={this.state.calendar.calendarWeekends}
                                        events={this.state.calendar.calendarEvents}
                                        dateClick={this.handleDateClick}
                                    />
                                )}
                                {_.isNull(MinDate) && (
                                    <div
                                        ref={(ref) => (this.calendarLoading = ref)}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            backgroundColor: "#ececee",
                                        }}
                                    />
                                )}
                                <LoadPanel
                                    position={{
                                        my: "center",
                                        of: this.calendarLoading ? this.calendarLoading : null,
                                    }}
                                    visible={this.calendarLoading && loadMonthPayroll}
                                    showIndicator={true}
                                    shading={false}
                                    showPane={true}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <div
                            className="display_row align-center"
                            style={{ flexDirection: "row", justifyContent: "space-between" }}
                        >
                            <div className={classes.text}>{currentMonth || ""}</div>
                            <div className="pd10 display_row align-center">
                                <span className="pdr15">
                                    <IconButton
                                        disabled={disabledLeft || showW29F2100}
                                        aria-label="view"
                                        size="small"
                                        onClick={this.goPrevMonth}
                                        className={classes.divIcon}
                                    >
                                        <Icons name="left" />
                                    </IconButton>
                                </span>
                                <span>
                                    <IconButton
                                        className={classes.divIcon}
                                        disabled={disabledRight || showW29F2100}
                                        aria-label="view"
                                        size="small"
                                        onClick={this.goNextMonth}
                                    >
                                        <Icons name="right" />
                                    </IconButton>
                                </span>
                            </div>
                        </div>
                        <Row>
                            {dataNote.length > 0
                                ? dataNote.map((item, index) => {
                                    const ColorCodeNote = item.WorkDayType === "NADate" ? "#FFFFFF" : item.ColorCode;
                                    return (
                                        <Col
                                            lg={12}
                                            md={12}
                                            sm={6}
                                            xs={4}
                                            className="display_row align-center pdt10"
                                            key={index}
                                        >
                                            <Icons
                                                className="fa fa-circle pdr5"
                                                style={{ fontSize: 20, color: ColorCodeNote }}
                                            />
                                            <span className={classes.divText}>
                                                {item.WorkDayTypeName}
                                            </span>
                                        </Col>
                                    );
                                })
                                : null}
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        (state) => ({}),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29F2100Actions: bindActionCreators(w29F2100Actions, dispatch),
        })
    ),
    withStyles(styles, { withTheme: true })
)(W29F2100);
