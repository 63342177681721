/**
 * @copyright 2019 @ DigiNet
 * @author TRANGHOANG
 * @create 4/30/2021
 * @Example
 */

import React, {useState, useEffect, useRef}                         from "react";
import { compose, bindActionCreators }           from "redux";
import { connect }                               from "react-redux";
import Modal                                     from "../../../common/modal/modal";
import {Col, Row, FormGroup} from "react-bootstrap";
import {
    makeStyles,
    FormControl,
} from "@material-ui/core";
import Config                                    from "../../../../config";
import {MForm}                            from "../../../common/form-material";
import ButtonGeneral                             from "../../../common/button/button-general";
import { TextField } from "@material-ui/core";
import * as W29F2072Actions                      from "../../../../redux/W2X/W29F2072/W29F2072_actions";
import * as generalActions                       from "../../../../redux/general/general_actions";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import InputMask from "react-input-mask";
import _ from "lodash";
import {Column, CustomRule, Summary, TotalItem} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import GridActionBar from "../../../grid-container/grid-actionbar";
import moment from "moment";
import Attachments from "../../../common/attachments/attachments";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import HeadClick from "../../../grid-container/head-click";
import History from "../../../libs/history";
import {TextInput, DatePicker, ButtonIcon, Checkbox, Accordion, AccordionDetails, AccordionSummary, Dropdown, Button} from "diginet-core-ui/components";
import Icon from 'diginet-core-ui/icons';
const useStyles = makeStyles(theme => ({
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        objectFit: "contain"
    },
    noneBorder: {
        margin:'0px!important',
        "&:before": {
            backgroundColor: "transparent"
        }
    },
    ExpansionPanelSummary: {
        padding: 0,
        maxWidth: 300,
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "12px 0"
        },
        "&.Mui-expanded": {
            minHeight: "inherit",
        },
    },
    rmUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
    },
    cboUnderline: {
        "& .dx-texteditor.dx-editor-underlined.dx-state-readonly:after": {
            borderBottomStyle: "double"
        }
    },
    bgRow: {
        "& td": {
            opacity: 0.6
        }
    },
    bgRowDiff: {
        "& td": {
            backgroundColor: "lightblue"
        }
    },
    hidden: {
        height: 0,
        overflow: "hidden",
        display: "none"
    },
    dpaper: {
        alignSelf: 'start',
        maxHeight: 'none !important',
    },
    dwrapper: {
        maxHeight: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%'
    },
}));

const _initDataForm = {
    IsConfirm01: 0,
    IsConfirm02: 0,
    IsConfirm03: 0,
    IsConfirm04: 0,
    IsConfirm05: 0,
    IsAfterOT: 0,
    IsBetweenOT: 0,
    IsPreOT: 0,
    ReasonOT: "",
    Note: "",
    OTTypeID: "",
    OTTypeName: "",
};

const W29F2072Popup = (props) => {
    const classes = useStyles();


    const _initDataGroupEmployee = {
        total: 0,
        rows: []
    };

    const {data, open, iPermission, onClose} = props;
    const { modeW29F2070, rowData } = data || {};
    const [errorHelperText, setErrorHelperText] = useState({});
    const [loadingForm, setLoadingForm] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataGridEmployee, setDataGridEmployee] = useState([]);
    const [isShowModalW09F6000, setIsShowModalW09F6000] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [loading, _setLoading] = useState({cboOtType: false, loadingDataGrid: false,
        loadingAttachment: false, loadingCaption: false, loadingLoadGridSetting: false});
    const [dataCboOtType, setDataCboOtType] = useState([]);
    const [loadGridSetting, setLoadGridSetting] = useState({});
    const [dataCaption, setDataCaption] = useState([]);
    const [dataCaptionGrid, setDataCaptionGrid] = useState([]);
    const [cboGroupEmployee, setCboGroupEmployee] = useState(_initDataGroupEmployee);
    const [openMaster, setOpenMaster] = useState(true);

    let dataGrid = useRef(null);
    const attRef = useRef(null);
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    let isChange = useRef(false);
    let dataOldEmployee = useRef([]);
    let dataOldMaster = useRef([]);
    let ReasonOTRef = useRef(null);
    let NoteRef = useRef(null);
    let editting = useRef(0);

    const loadCboOtType = () => {
        const params = {
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            OTFrom: dataForm?.OTFrom || "",
            OTTo: dataForm?.OTTo || "",
            EmployeeID: "",
            ShiftID: dataForm?.ShiftID || "",
            Language: Config.language || 84,
        };
        setLoading({cboOtType: true});
        props.w29f2072Actions.getCboOtType(params, (error, data) => {
            setLoading({cboOtType: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCboOtType(data);
            }
        });
    };

    const loadCaption = () => {
        setLoading({loadingCaption: true});

        props.w29f2072Actions.getCaptionMaster((error, data) => {
            setLoading({loadingCaption: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCaption(data);
            }
        });
    };

    const getGridCaption = () => {
        props.w29f2072Actions.getGridCaption((error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCaptionGrid(data);
            }
        });
    };

    const getGridSetting = () => {
        setLoading({loadingLoadGridSetting: true});
        props.w29f2072Actions.loadGridSetting((error, data) => {
            setLoading({loadingLoadGridSetting: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setLoadGridSetting(data);
            }
        });
    };

    const loadAttachments = () => {
        const {TransID} = rowData;
        const param = {
            KeyID: TransID || "",
            TableName: "D75T4074"
        };
        setLoading({loadingAttachment: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({loadingAttachment: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    const loadCboChooseGroupEmployee = () => {
        const params = {
            ...dataForm,
            ReasonOT: ReasonOTRef.current.value || "",
            Note: NoteRef.current.value || "",
            AttendanceDate: dataForm.AttendanceDate ? moment(dataForm.AttendanceDate, "YYYY/MM/DD").format("YYYY-MM-DD") : null,
        };
        props.w29f2072Actions.getCboChooseGroupEmployee(params,(error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                const dataGrid = getDataFilterGrid(data,dataGridEmployee);
                dataGrid.forEach((i, idx)=>{
                    dataGrid[idx].PreOTFrom = formatTime(i.PreOTFrom);
                    dataGrid[idx].PreOTTo = formatTime(i.PreOTTo);
                    dataGrid[idx].AfterOTFrom = formatTime(i.AfterOTFrom);
                    dataGrid[idx].AfterOTTo = formatTime(i.AfterOTTo);
                    dataGrid[idx].BerOTToOTTo = formatTime(i.BerOTToOTTo);
                    dataGrid[idx].BerOTToOTFrom= formatTime(i.BerOTToOTFrom);
                    dataGrid[idx].PreOTFromPreOTTo = `${i.PreOTFrom || "__:__"} - ${i.PreOTTo || "__:__"}`;
                    dataGrid[idx].AfterOTFromAfterOTTo = `${i.AfterOTFrom || "__:__"} - ${i.AfterOTTo || "__:__"}`;
                    dataGrid[idx].BetweenOTFromBetweenOTTo = `${i.BetweenOTFrom || "__:__"} - ${i.BetweenOTTo || "__:__"}`;
                    dataGrid[idx].IsCheck = !!i.IsCheck;
                    dataGrid[idx].AddEmp = i.AddEmp || 0;
                });
                setDataGridEmployee(dataGrid);
            }
        });
    };

    const loadCboGroupEmployee = () => {
        const params = {
            FormID: "W29F2070",
            Language: Config.language || 84,
            Mode: 0,
            skip: 0,
            limit: 50,
            search: "W29F2070",
            search2: ""
        };
        props.w29f2072Actions.getCboGroupEmployee(params,(error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setCboGroupEmployee({
                    total: data.total,
                    rows: data.rows
                });
            }
        });
    };

    const loadFormMaster = () => {
        const {TransID} = rowData;
        setLoadingForm(true);
        const params = {
            FormID: "W29F2070",
            TransID: TransID || null,
            Language: Config.language || 84,
        };

        props.w29f2072Actions.getForm(params, (error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                if(data.Note) NoteRef.current.value = data.Note;
                if(data.ReasonOT) ReasonOTRef.current.value = data.ReasonOT;
                if(modeW29F2070 === "edit") {
                    dataOldMaster.current = JSON.parse(JSON.stringify(data));
                }
                setDataForm(data);
                const detail = [...data.detail];
                dataOldEmployee.current=detail.map(rs=>({...rs}));
                detail.forEach((i, idx)=>{
                    detail[idx].PreOTFromPreOTTo = `${i.PreOTFrom || "__:__"} - ${i.PreOTTo || "__:__"}`;
                    detail[idx].AfterOTFromAfterOTTo = `${i.AfterOTFrom || "__:__"} - ${i.AfterOTTo || "__:__"}`;
                    detail[idx].BetweenOTFromBetweenOTTo = `${i.BetweenOTFrom || "__:__"} - ${i.BetweenOTTo || "__:__"}`;
                    detail[idx].IsCheck = !!i.IsCheck;
                });
                setDataGridEmployee(detail);
            }
            setLoadingForm(false);
        });
    };

    useEffect(() => {
        getGridSetting();
        loadCboOtType();
        loadCaption();
        getGridCaption();

        if(modeW29F2070 !== "add"){
            loadFormMaster();
            // loadGridEmployee();
            loadAttachments();
        }
        if(modeW29F2070 !== "view"){
            loadCboGroupEmployee();
        }
        if(modeW29F2070 === "view") {
            setIsModeView(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.modeW29F2070]);

    useEffect(() => {
        if(dataForm.AttendanceDate === null || isChange.current) {
            isChange.current = false;
            setData({OTFrom: "",OTTo: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.AttendanceDate]);

    useEffect(() => {
        if (dataForm.OTFrom && dataForm.OTTo && (!dataForm.OTFrom.includes("_") || !dataForm.OTTo.includes("_"))) {
            let checkValidOTFrom = moment(dataForm.OTFrom, "HH:mm").format("HH:mm");
            let checkValidOTTo = moment(dataForm.OTTo, "HH:mm").format("HH:mm");
            if (checkValidOTFrom >= checkValidOTTo) {
                setStateErrorText({
                    errorTime: Config.lang("Gio_tang_ca_khong_hop_le"),
                });
            } else {
                setStateErrorText({ errorTime: "" });
            }
            if (checkValidOTTo <= checkValidOTFrom) {
                setStateErrorText({
                    errorTime: Config.lang("Gio_tang_ca_khong_hop_le"),
                });
            } else {
                setStateErrorText({ errorTime: "" });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm.OTFrom, dataForm.OTTo]);

    useEffect(() => {
        if(dataForm?.GroupID) {
            loadCboChooseGroupEmployee();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm?.GroupID]);

    const resetForm = () => {
        setDataForm(_initDataForm);
        setDataGridEmployee([]);
        attachments = [];
        deletedFile = [];
        dataGrid = null;
    };

    const onClosePopup = (isReload = false) => {
        if(onClose) onClose(isReload);
    };

    const setStateErrorText = (objValue) => {
        if(objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setLoading = (obj) => {
        _setLoading({...loading, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const openEmployeeSelect = () => {
        setIsShowModalW09F6000(true);
    };

    const getDataFilterGrid = (dataUser,dataGridEmployee) => {
        let dataGrid = [];
        if(dataGridEmployee.length > 0) {
            let dataFilter = dataGridEmployee.map(item => { return item.EmployeeID });
            dataGrid = dataUser.filter(f => !dataFilter.includes(f.EmployeeID));
        }
        dataGrid = dataGridEmployee && dataGridEmployee.length > 0 ? dataGridEmployee.concat(dataGrid) : dataUser;
        return  dataGrid || [];
    };

    const formatTime = (time) => {
        if(time && !time.includes(":") && time.length === 4){
            return moment(time,"HHmm").format("HH:mm");
        }
        return time || "";
    };

    const onSelectedEmployee = (e) => {
        const params ={
            ...dataForm,
            GroupID: dataForm.GroupID || "",
            ReasonOT: ReasonOTRef.current.value || "",
            Note: NoteRef.current.value || "",
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
        };
        setIsShowModalW09F6000(false);
        props.w29f2072Actions.getEmployee(params, (err, data)=>{
            if(err){
                Config.popup.show("ERROR", err);
                return false;
            }
            data.forEach(i=>{
                i.AddEmp = 0;
                i.IsCheck = false;
                i.PreOTFrom = formatTime(i.PreOTFrom);
                i.PreOTTo = formatTime(i.PreOTTo);
                i.AfterOTFrom = formatTime(i.AfterOTFrom);
                i.AfterOTTo = formatTime(i.AfterOTTo);
                i.BerOTToOTTo = formatTime(i.BerOTToOTTo);
                i.BerOTToOTFrom= formatTime(i.BerOTToOTFrom);
            });

            const dataGrid = getDataFilterGrid(data,dataGridEmployee);
            dataGrid.forEach((i)=> {
                i.PreOTFromPreOTTo = `${i.PreOTFrom || "__:__"} - ${i.PreOTTo || "__:__"}`;
                i.AfterOTFromAfterOTTo = `${i.AfterOTFrom || "__:__"} - ${i.AfterOTTo || "__:__"}`;
                i.BetweenOTFromBetweenOTTo = `${i.BetweenOTFrom || "__:__"} - ${i.BetweenOTTo || "__:__"}`;
            });
            setDataGridEmployee(dataGrid);
        });
    };

    const closeEmployeeSelect = () => {
        setIsShowModalW09F6000(false);
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.map((d) => d.AttachmentID);
            const dataNew = [...dataOldAttachments].filter(att=>_arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        switch (key) {
            case "AttendanceDate":
                setData({AttendanceDate: value});
                isChange.current = true;
                break;
            case "OTTypeID":
                setData({OTTypeID: value});
                break;
            case "GroupID":
                setData({GroupID: value});
                break;
            case "ReasonOT":
                setData({ReasonOT: value});
                break;
            case "Note":
                setData({Note: value});
                break;
            default:
                let obj = {};
                obj[key] = +e;
                setData(obj);
        }
    };

    const formatDateDataSource = (data) => {
        let dataFormat = data.map(item => {
            item.AttendanceDate = item.AttendanceDate ? moment(item.AttendanceDate).format("YYYY-MM-DD") : null;
            if(_.isBoolean(item.IsConfirm01)) item.IsConfirm01 = Number(item.IsConfirm01);
            if(_.isBoolean(item.IsConfirm02)) item.IsConfirm02 = Number(item.IsConfirm02);
            if(_.isBoolean(item.IsConfirm03)) item.IsConfirm03 = Number(item.IsConfirm03);
            if(_.isBoolean(item.IsConfirm04)) item.IsConfirm04 = Number(item.IsConfirm04);
            if(_.isBoolean(item.IsConfirm05)) item.IsConfirm05 = Number(item.IsConfirm05);
            delete item.BetweenOTFromBetweenOTTo;
            delete item.AfterOTFromAfterOTTo;
            delete item.PreOTFromPreOTTo;
            return item;
        });
        return dataFormat;
    };

    const saveGrid = () => {
        dataGrid.instance.saveEditData().done(() => {
            setTimeout(() => {
                if (editting.current === 0) {
                    onSave();
                }
                else{
                    editting.current = 0;
                }
            }, 200);
        });
    };

    const onSave = () => {
        let actionSaveMode = modeW29F2070 === "add" ? "add" : "edit";
        const dataSource = dataGrid.instance.option("dataSource");
        const dataT = JSON.parse(JSON.stringify(dataSource));
        const dataNew = dataT.map((item, idx) => {
            if(item.PreOTFromPreOTTo.includes("_")) {
                item.PreOTFromPreOTTo="";
                item.PreOTFrom="";
                item.PreOTTo="";
            }
            else{
                item.PreOTFrom=item.PreOTFromPreOTTo.split(" - ")[0].replace(/_|:/g, "");
                item.PreOTTo=item.PreOTFromPreOTTo.split(" - ")[1].replace(/_|:/g, "");
            }

            if(item.AfterOTFromAfterOTTo.includes("_")) {
                item.AfterOTFromAfterOTTo="";
                item.AfterOTFrom="";
                item.AfterOTTo="";
            }
            else{
                item.AfterOTFrom=item.AfterOTFromAfterOTTo.split(" - ")[0].replace(/_|:/g, "");
                item.AfterOTTo=item.AfterOTFromAfterOTTo.split(" - ")[1].replace(/_|:/g, "");
            }

            if(item.BetweenOTFromBetweenOTTo.includes("_")){
                item.BetweenOTFromBetweenOTTo="";
                item.BetweenOTFrom="";
                item.BetweenOTTo="";
            }
            else{
                item.BetweenOTFrom=item.BetweenOTFromBetweenOTTo.split(" - ")[0].replace(/_|:/g, "");
                item.BetweenOTTo=item.BetweenOTFromBetweenOTTo.split(" - ")[1].replace(/_|:/g, "");
            }

            item.IsCheck=+!!item.IsCheck;
            return item;
        });

        // check OT time is null
        const arrError = dataNew.find(item=>{
            const isDeleted = (!item.Deleted && modeW29F2070 === "edit") || modeW29F2070 !== "edit";
            const isPreOT = !!dataForm.IsPreOT && !!item.PreOTFrom && !!item.PreOTTo;
            const isAfterOT = !!dataForm.IsAfterOT && !!item.AfterOTFrom && !!item.AfterOTTo;
            const isBetweenOT = !!dataForm.IsBetweenOT && !!item.BetweenOTTo && !!item.BetweenOTFrom;
            return !isPreOT && !isBetweenOT && !isAfterOT && isDeleted;
        });

        if(arrError){
            let message = Config.lang("Ton_tai_nhan_vien_chua_co_thoi_gian_dang_ky_tang_ca");
            Config.popup.show('ERROR', message);
            return;
        }

        // check ShiftID is null
        const arrErrorShiftID = dataSource.find(item=>!item.ShiftID);
        if(arrErrorShiftID){
            let message = Config.lang("Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu");
            Config.popup.show('ERROR', message);
            return;
        }

        const validateRules = [
            {
                name: "AttendanceDate",
                rules: ["isRequired"],
                value: dataForm.AttendanceDate || ""
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if(setStateErrorText(validateForm)) return false;
        }

        if(errorHelperText['errorTime']) {
            return
        }

        const arrAttachments = _getAttachments();
        const params = {
            ...dataForm,
            Language: Config.language || 84,
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            data: dataNew ? JSON.stringify(formatDateDataSource(dataNew)) : "",
            arrAttachment: JSON.stringify(arrAttachments),
            IsConfirm01: dataForm?.IsConfirm01 || 0,
            IsConfirm02: dataForm?.IsConfirm02 || 0,
            IsConfirm03: dataForm?.IsConfirm03 || 0,
            IsConfirm04: dataForm?.IsConfirm04 || 0,
            IsConfirm05: dataForm?.IsConfirm05 || 0,
            IsAfterOT: dataForm?.IsAfterOT || 0,
            IsBetweenOT: dataForm?.IsBetweenOT || 0,
            IsPreOT: dataForm?.IsPreOT || 0,
            ReasonOT: ReasonOTRef.current.value || "",
            Note: NoteRef.current.value || "",
            OTTypeID: dataForm?.OTTypeID || "",
            OTTypeName: dataForm?.OTTypeName || "",
        };
        delete params.detail;
        props.w29f2072Actions[actionSaveMode](params, async (errors, data) => {
                if (errors) {
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    await saveHistory(params, actionSaveMode, data.TransID);
                    resetForm();
                    onClosePopup(true);
                }
            });

    };

    const saveHistory = async (data, mode, _TransID) => {
        if(!data) return false;
        const TransID = mode === "add" ? _TransID :  _.get(data, "TransID", "");
        const _mode = mode === "add" ? 0 : 1;
        let dataSource = dataGrid.instance.option('dataSource');
        if (TransID) {

            //name Content
            const captions = {
                AttendanceDate: "ngay_cong",
                IsPreOT: "tang_ca_truoc",
                IsBetweenOT: "tang_ca_giua",
                IsAfterOT: "tang_ca_sau",
                OTTypeName: "loai_tach_tang_ca",
                ReasonOT: "ly_do_tang_ca",
                Note: "ghi_chu",
                OTTypeID: "Loai_tach_tang_ca",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
            };
            // name detail grid
            let detailCaptions = {
                TransID: "TransID",
                EmployeeID: "ma_nhan_vien",
                EmployeeName: "ten_nhan_vien",
                ShiftInOut: "ca_vao_ra",
                PreOT: "tang_ca_truoc",
                AfterOT: "tang_ca_sau",
                BetweenOT: "tang_ca_giua",
                OTTypeName: "loai_tach_tang_ca",
                Reason: "ly_do_tang_ca",
                Note: "ghi_chu",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
                ShiftID: "data_ ShiftID",
                OTTypeID: "data_ OTTypeID",

            };
            // data form master
            const _dataOld = dataOldMaster.current;

            const options = {
                data: data,
                captions: captions,
                dataCompare: _dataOld,
                action: _mode,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2070",
                TransactionName: "Lập lịch tăng ca hàng loạt",
                // itemRender: (e, type) => {
                //     const {key} = e;
                //     if (key === "PreOT") {
                //         if (type === "Content_DETAIL_OLD") {
                //             const {oldData} = e;
                //             return oldData.PreOTFrom ? `${oldData.PreOTFrom} - ${oldData.PreOTTo}` : "";
                //         }
                //         if (type === "Content_DETAIL_NEW") {
                //             const {data} = e;
                //             return data.PreOTFrom ? `${data.PreOTFrom} - ${data.PreOTTo}` : "";
                //         }
                //     }
                // }
            };

            const dataOld = dataOldEmployee.current.map(i=>{
                i.PreOT = i.PreOTFrom ? `${i.PreOTFrom} - ${i.PreOTTo}` : "";
                i.AfterOT = i.AfterOTFrom ? `${i.AfterOTFrom} - ${i.AfterOTTo}` : "";
                i.BetweenOT = i.BetweenOTFrom ? `${i.BetweenOTFrom} - ${i.BetweenOTTo}` : "";
                i.IsCheck = +!!i.IsCheck;
                return i;
            });

            const dataNew = dataSource.map(i=>{
                i.PreOT = i.PreOTFrom ? `${i.PreOTFrom} - ${i.PreOTTo}` : "";
                i.AfterOT = i.AfterOTFrom ? `${i.AfterOTFrom} - ${i.AfterOTTo}` : "";
                i.BetweenOT = i.BetweenOTFrom ? `${i.BetweenOTFrom} - ${i.BetweenOTTo}` : "";
                i.IsCheck = +!!i.IsCheck;
                return i;
            });

            const history = new History(options); //Init history
            history.createDetailHistory("Nhan_vien", dataNew, dataOld, detailCaptions, "EmployeeID", null, options); //Create detail..
            await history.save();
        }
    };

    const onCancel = (e) => {
        if(!e) return null;
        Config.popup.show("YES_NO", Config.lang('Bạn có chắc chắn muốn huỷ? Nếu huỷ thì sẽ KHÔNG THỂ khôi phục dữ liệu trước đó'),
            //Choosen All
            () => { //onConfirm
                const {rowIndex} = e.row;
                props.w29f2072Actions.cancelEmployee({TransID: e.data?.TransID}, async (error, data) => {
                    if (error) {
                        Config.popup.show('ERROR', error);
                        return false;
                    }
                    if(data && data.Status === 0) {
                        e.component.cellValue(rowIndex, "IsCancel", 2);
                        e.component.refresh();
                        Config.notify.show('success', Config.lang("Da_huy_thanh_cong"), 2000);
                        await saveHistoryEmployee(e);
                    }
                });
            })
    };

    const saveHistoryEmployee = async (e) => {
        const {data} = e;

        const _data = {
            ...data,
            PreOT: (data?.PreOTFrom || data?.PreOTTo) ? `${data?.PreOTFrom} - ${data?.PreOTTo}` : '',
            AfterOT: (data?.AfterOTFrom || data?.AfterOTTo) ? `${data?.AfterOTFrom} - ${data?.AfterOTTo}` : '',
            BetweenOT: (data?.BetweenOTFrom || data?.BetweenOTTo) ? `${data?.BetweenOTFrom} - ${data?.BetweenOTTo}` : '',
        };

        //name Content
        const captions = {
            TransID: "TransID",
            EmployeeID: "ma_nhan_vien",
            EmployeeName: "ten_nhan_vien",
            ShiftInOut: "ca_vao_ra",
            PreOT: "tang_ca_truoc",
            AfterOT: "tang_ca_sau",
            BetweenOT: "tang_ca_giua",
            OTTypeName: "loai_tach_tang_ca",
            Note: "ghi_chu",
            IsConfirm01: "data_IsConfirm01",
            IsConfirm02: "data_IsConfirm02",
            IsConfirm03: "data_IsConfirm03",
            IsConfirm04: "data_IsConfirm04",
            IsConfirm05: "data_IsConfirm05",
            ShiftID: "data_ShiftID",
            OTTypeID: "data_OTTypeID",
        };

        const options = {
            "ModuleID": "D29",
            "TransactionID": "W29F2070",
            "TransactionName": "Lập lịch tăng ca hàng loạt",
            "TransID": data.TransID,
            "action": 3,
            "data": _data,
            "captions":captions
        };

        const history = new History(options); //Init history
        await history.save();
    };

    const onDelete = (mode,e) => {
        if(!e) return null;
        const { data, rowIndex } = e.row;
        if(mode === "add") {
            const dataFilter = dataGridEmployee.filter(item => item.EmployeeID !== data.EmployeeID);
            setDataGridEmployee(dataFilter);
        }
        if(mode === "edit") {
            const dataT = [...dataGridEmployee];
            dataT[rowIndex].Deleted = 1;
            setDataGridEmployee(dataT);
            e.component.cellValue(e.rowIndex, "Deleted", 1);
            e.component.saveEditData();
        }
    };

    const cellRenderAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
          <GridActionBar>
              <ButtonIcon
                  circular={true}
                  size={"tiny"}
                  viewType={"text"}
                  disabled={data.IsDel !== 0}
                  name={"delete"}
                  style={{marginRight: 5, padding:'0 10px'}}
                  onClick={() => {
                      onDelete(modeW29F2070,e);
                  }}
              />
              <ButtonIcon
                  circular={true}
                  size={"tiny"}
                  viewType={"text"}
                  name={"cancel"}
                  style={{padding:'0 10px'}}
                  disabled={data.IsCancel !== 1}
                  onClick={()=>onCancel(e)}
              />
          </GridActionBar>
        );
    };

    const InputMaskRef = useRef([]);

    const renderOT = (e, key1, key2) => {
        if(!e) return null;
        const {data} = e;
        const {rowIndex} = e.row;

        return (
            <FormControl variant={"standard"}
                         error={!!errorHelperText}
                         style={{display:'flex', flexDirection:"row", alignItems:'center'}}
                         fullWidth={true}>
                <InputMask
                    mask={'12:34 - 12:34'}
                    alwaysShowMask={true}
                    formatChars={{
                        '1': '[0-9]',
                        '2': '[0-9]',
                        '3': '[0-5]',
                        '4': '[0-9]',
                    }}
                    defaultValue={`${data[key1]} - ${data[key2]}`}
                    onBlur={(eChild) => {
                        let from = eChild.target.value.split(' - ')[0];
                        if(from && parseInt(from.split(":")[0]) > 24){
                            from = `24:${from.split(":")[1]}`
                        }
                        let to = eChild.target.value.split(' - ')[1];
                        if(to && parseInt(to.split(":")[0]) > 24){
                            to = `24:${to.split(":")[1]}`
                        }

                        if (dataGridEmployee[rowIndex][`${key1}${key2}`] !== `${from} - ${to}`) {
                            e.component.cellValue(rowIndex, key1, from);
                            e.component.cellValue(rowIndex, key2, to);
                            e.component.cellValue(rowIndex, `${key1}${key2}`, `${from} - ${to}`);
                        }
                    }}
                    onChange={(eChild)=>{
                        const {value} = eChild.target;

                        //fromOT
                        let from = value.split(' - ')[0];
                        if(from.split("_").length <= 3){
                            from = from.replace(/_/g,"0");
                        }

                        //toOT
                        let to = value.split(' - ')[1];
                        if(to.split("_").length <= 3){
                            to = to.replace(/_/g,"0");
                        }

                        //check fromOT
                        if(from.split("_").length === 4 && from[0] !== "_"){
                            const firstLetterFrom = parseInt(from[0]);
                            from = (firstLetterFrom >= 3 ? `0${firstLetterFrom}` : `${firstLetterFrom}0`) + `:00`;
                        }

                        //check toOT
                        else if(to.split("_").length === 4 && to[0] !== "_"){
                            const firstLetterTo = parseInt(to[0]);
                            to = (firstLetterTo >= 3 ? `0${firstLetterTo}` : `${firstLetterTo}0`) + `:00`;
                        }

                        //set value input
                        InputMaskRef.current[rowIndex].value = `${from} - ${to}`;
                    }}
                >
                    {(props) => {
                        return <TextField {...props}
                                          inputRef={ref=>{InputMaskRef.current[rowIndex] = ref}}
                                          required={true}
                                          variant={"standard"}/>}
                    }
                </InputMask>
            </FormControl>
        )
    };

    const selectedRange = useRef(null);
    const filterColIndx = useRef(null);
    const filterGrid = useRef({});
    const [filterGridValue, setFilterGridValue] = useState([]);

    const getFilterGrid = (isClear) => {
        if (isClear) filterGrid.current = {};
        const filter = filterGrid.current;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    const renderHeaderColumns = (e) => {
        if (!e) return false;
        return(
            <HeadClick selectedRange={selectedRange.current}
                       dataHeaderCell={e}
                       allowClick={true}
                       onUpdate={(data,column)=>{
                           data.Updated = 1;
                           if(column.dataField === "AfterOTFromAfterOTTo" && data["AfterOTFromAfterOTTo"] && !data["AfterOTFromAfterOTTo"].includes("__")){
                               data["AfterOTFrom"] = data["AfterOTFromAfterOTTo"].split(" - ")[0];
                               data["AfterOTTo"] = data["AfterOTFromAfterOTTo"].split(" - ")[1];
                           }
                           if(column.dataField === "PreOTFromPreOTTo" && data["PreOTFromPreOTTo"] && !data["PreOTFromPreOTTo"].includes("__")){
                               data["PreOTFrom"] = data["PreOTFromPreOTTo"].split(" - ")[0];
                               data["PreOTTo"] = data["PreOTFromPreOTTo"].split(" - ")[1];
                           }
                           if(column.dataField === "BetweenOTFromBetweenOTTo" && data["BetweenOTFromBetweenOTTo"] && !data["BetweenOTFromBetweenOTTo"].includes("__")){
                               data["BetweenOTFrom"] = data["BetweenOTFromBetweenOTTo"].split(" - ")[0];
                               data["BetweenOTTo"] = data["BetweenOTFromBetweenOTTo"].split(" - ")[1];
                           }
                       }}
                       conditionCopy={(eChild) => {
                           const {data} = eChild;
                           const dataSource = dataGrid.instance.option("dataSource");
                           return (dataSource.find(i=>!!i.IsCheck) && eChild.key !== "IsCheck" ) ? data.IsCheck : 1;
                       }}
            />
        )
    };

    const validateRequired = (e, key1, key2) => {
        const {data} = e;
        let from = data[key1];
        let to = data[key2];
        if((to || from) && (to !== "__:__" || from !== "__:__") && (to?.includes("_") || from?.includes("_"))){
            return false;
        }
        return true;
    };

    const onEditingStart = (e) => {
        const { data } = e;
        if (data.IsUpdate === 1) {
            e.cancel = true;
        }
    };

    const calculateSelectedRow = (options) => {
        if (options.name === 'RowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if (!options.value?.Deleted) {
                    options.totalValue += 1;
                }
            }
        }
    };

    const checkShowCboOTType = loadGridSetting?.IsUseOTType;
    const isOT = dataForm?.IsPreOT === 1 || (dataForm?.IsBetweenOT === 1 && loadGridSetting.IsBetweenOT === 1) || dataForm?.IsAfterOT === 1;
    return (
      <>
          {isShowModalW09F6000 && <W09F6000
            open={isShowModalW09F6000}
            FormID={"W29F2070"}
            AttendanceDate={dataForm?.AttendanceDate}
            onChosen={onSelectedEmployee}
            onClose={closeEmployeeSelect} />
          }
          <Modal
            open={open}
            classes={{dpaper: classes.dpaper, dwrapper: classes.dwrapper}}
            fullScreen
          >
              <Modal.Title disableTypography>
                  <ActionToolbar
                    alignment={"space-between"}
                    title={Config.lang("Lap_lich_tang_ca_hang_loat")}
                    showBorder={false}
                    upperCase={false}
                    className={"text-uppercase"}
                    style={{
                        marginTop: 0,
                    }}
                  >
                      <div className={"display_row align-center"}>
                          <Button
                              color="primary"
                              startIcon="Person"
                              text={Config.lang('Chon_nhan_vien')}
                              disabled={ isModeView || iPermission <= 1 || !dataForm?.AttendanceDate || !isOT}
                              style={{ textTransform: 'uppercase', margin: "0px 21px", padding: "6px 12px"}}
                              onClick={openEmployeeSelect}
                          />
                          <Dropdown
                              readOnly={isModeView}
                              placeholder={Config.lang("Chon_nhom_nhan_vien")}
                              disabled={isModeView || iPermission <= 1 || !dataForm?.AttendanceDate || !isOT}
                              margin={"normal"}
                              color={"primary"}
                              viewType={"outlined"}
                              dataSource={cboGroupEmployee.rows}
                              displayExpr={"GroupName"}
                              valueExpr={"GroupID"}
                              style={{margin: "0 4px"}}
                              clearAble={true}
                              onChange={e => handleChange("GroupID", e)}
                          />
                      </div>
                      <ButtonIcon name={"close"}
                                  viewType={"text"}
                                  circular={true}
                                  size={"tiny"}
                                  style={{padding: 15}}
                                  onClick={() => onClosePopup(false)}
                      />
                  </ActionToolbar>

              </Modal.Title>
              <Modal.Content>
                  <FormGroup>
                      <Accordion expand
                                 onCollapse={()=>setOpenMaster(false)}
                                 onExpand={()=>setOpenMaster(true)}
                      >
                          <AccordionSummary>
                              {Config.lang("Thong_tin_chung")}
                          </AccordionSummary>
                          <AccordionDetails>
                              <Row>
                                  <Col xs={12} sm={12} md={6}>
                                      <div style={{marginBottom: 20}}>
                                          <DatePicker
                                              actionIconAt={'start'}
                                              placeholder={'DD/MM/YYYY'}
                                              displayFormat={'DD/MM/YYYY'}
                                              value={dataForm.AttendanceDate}
                                              label={Config.lang("Ngay_tang_ca")}
                                              disabled={isModeView}
                                              onChange={e => handleChange("AttendanceDate", e)}
                                              error={errorHelperText && errorHelperText['AttendanceDate']}
                                              required={true}
                                          />
                                      </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={6}>
                                      {!!checkShowCboOTType &&
                                        <Dropdown
                                              error={errorHelperText && errorHelperText["WorkDayType"]}
                                              readOnly={isModeView}
                                              label={Config.lang("Loai_tach_tang_ca")}
                                              margin={"normal"}
                                              viewType={"underlined"}
                                              dataSource={dataCboOtType}
                                              displayExpr={"OTTypeName"}
                                              valueExpr={"OTTypeID"}
                                              value={dataForm.OTTypeID}
                                              clearAble={true}
                                              onChange={e => handleChange("OTTypeID", e)}
                                          />
                                      }
                                  </Col>
                              </Row>
                              <Row>
                                  <Col xs={12} sm={12} md={12} className={"display_row"}>
                                      {["IsPreOT", "IsBetweenOT", "IsAfterOT"].map((vl, idx)=>{
                                          const name = new Map([
                                              ["IsPreOT", Config.lang("Tang_ca_truoc")],
                                              ["IsBetweenOT", Config.lang("Tang_ca_giua")],
                                              ["IsAfterOT", Config.lang("Tang_ca_sau")],
                                          ]);
                                          if(idx === 1 && !loadGridSetting?.IsBetweenOT) return null;
                                          return(
                                                <Checkbox
                                                    key={idx}
                                                    checked={Boolean(dataForm[vl])}
                                                    onChange={(e) => handleChange(vl,e.target.checked)}
                                                    label={name.get(vl)}
                                                    disabled={isModeView}
                                                    style={{margin: '0 10px 10px 0'}}
                                                />
                                          )
                                      })}
                                  </Col>
                                  <Col xs={12} sm={12} md={12} className={"display_row"}>
                                      {
                                      dataCaption.map((item,indx) => {
                                          if(!item.Disabled) return null;
                                          return (
                                              <Checkbox
                                                  key={indx}
                                                  checked={Boolean(dataForm[item.RefID])}
                                                  onChange={(e) => handleChange(item.RefID,e.target.checked)}
                                                  label={item.RefCaption}
                                                  disabled={isModeView}
                                                  style={{marginRight: 10}}
                                              />
                                          )
                                      })
                                  }
                                </Col>
                              </Row>
                              <Row>
                                  <Col xs={12}>
                                      <TextInput
                                          inputRef={ReasonOTRef}
                                          placeholder={Config.lang("Ly_do_tang_ca")}
                                          label={Config.lang("Ly_do_tang_ca")}
                                          disabled={loadingForm}
                                      />
                                  </Col>
                                  <Col xs={12}>
                                      <TextInput
                                          inputRef={NoteRef}
                                          label={Config.lang("Ghi_chu")}
                                          placeholder={Config.lang("Ghi_chu")}
                                          disabled={loadingForm}
                                      />
                                  </Col>
                              </Row>
                          </AccordionDetails>
                      </Accordion>
                  </FormGroup>
                  <FormGroup>
                      <Accordion expand>
                          <AccordionSummary>
                              {Config.lang("Danh_sach_nhan_vien")}
                          </AccordionSummary>
                          <AccordionDetails>
                              <Row>
                                <Col xs={12}>
                                  <GridContainer
                                    reference={ref => dataGrid = ref}
                                    dataSource={dataGridEmployee}
                                    keyExpr={"EmployeeID"}
                                    columnAutoWidth={true}
                                    showRowLines={true}
                                    showBorders={false}
                                    showColumnLines={false}
                                    noDataText={Config.lang("No_data")}
                                    hoverStateEnabled={true}
                                    rowAlternationEnabled={false}
                                    style={{border: "none", overflowX: "scroll"}}
                                    height={openMaster ? 400 : Config.getHeightGrid() - 80}
                                    typePaging={"remote"}
                                    pagerFullScreen={false}
                                    onEditingStart={onEditingStart}
                                    editing={{
                                        mode: 'cell',
                                        refreshMode: 'full',
                                        allowUpdating: true,
                                    }}
                                    onEditorPreparing={(e) => {
                                        //QC yêu cầu thêm delay filter
                                        if((e.parentType === "searchPanel" || e.parentType === "filterRow") && e.dataField === "EmployeeID") {
                                            e.updateValueTimeout = 2500;
                                        }
                                    }}
                                    onRowPrepared={(e) => {
                                        if(!e) return false;
                                        const {data} = e;
                                        if (data?.Updated === 1 || data?.AddEmp === 0){
                                            e.rowElement.classList.add(classes.bgRowDiff);
                                        }
                                        if (data?.IsCancel === 2){
                                            e.rowElement.classList.add(classes.bgRow);
                                        }
                                        if (data?.Deleted === 1){
                                            e.rowElement.classList.add(classes.hidden);
                                        }
                                    }}
                                    filterRow={{
                                      visible: true,
                                      showOperationChooser: false,
                                    }}
                                    filterPanel={{
                                        visible: true
                                    }}
                                    sorting={{
                                      mode: "none"
                                    }}
                                    filterBuilderPopup={{
                                      onInitialized: (e) => {
                                          e.component.dispose();
                                          e.element.remove();
                                      }
                                    }}
                                    onCellSelectionChanged={(e) => {
                                        if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                            selectedRange.current = e.selectedRange;
                                        }
                                    }}
                                    onRowValidating={e=>{
                                      editting.current = +!e.isValid;
                                    }}
                                    filterValue={filterGridValue}
                                    onCellPrepared={e=>e.separator=","}
                                    onFilterGrid={(e) => {
                                        let {column, filterValue, columnIndex} = e;
                                        filterColIndx.current= columnIndex;
                                        e.component.saveEditData();
                                        if (column) {
                                            if (column.dataField === "EmployeeID") {
                                                let dataFilter = [];
                                                if (filterValue) {
                                                    if (filterValue.indexOf(",") > -1) {
                                                        const arrFilters = filterValue.split(",");
                                                        arrFilters.forEach(f => {
                                                            dataFilter.push(["EmployeeID", "=", f.trim()]);
                                                            dataFilter.push("or");
                                                        });
                                                        dataFilter.pop();
                                                    } else {
                                                        dataFilter = ["EmployeeID", "contains", filterValue];
                                                    }
                                                }
                                                filterGrid.current.EmployeeID = dataFilter;
                                            } else {
                                                let operator = "contains";
                                                if (["OTTypeID", "IsCheck"].includes(column.dataField)) operator = "=";
                                                filterGrid.current[column.dataField] = (filterValue || typeof filterValue === "boolean") ? [column.dataField, operator, filterValue] : [];
                                            }
                                            setFilterGridValue(getFilterGrid());
                                        } else {
                                            filterGrid.current = {};
                                            setFilterGridValue([]);
                                        }
                                    }}
                                    onRowUpdated={e=>{
                                        const {data} = e;
                                        data.Updated = 1;
                                    }}
                                  >
                                      <Column
                                          caption={Config.lang("Hanh_dong")}
                                          alignment={"center"}
                                          visible={Config.isMobile && !isModeView}
                                          allowEditing={false}
                                          cellRender={cellRenderAction}
                                      />
                                      <Column
                                          caption={Config.lang("Chon")}
                                          dataField={'IsCheck'}
                                          trueText={Config.lang("Có")}
                                          falseText={Config.lang("Không")}
                                          dataType={"boolean"}
                                          allowEditing={true}
                                          fixed={true}
                                          allowFiltering={true}
                                          headerCellRender={renderHeaderColumns}
                                          allowCopying={true}
                                          allowPasting={true}
                                          minWidth={80}
                                      />
                                      <Column
                                        caption={Config.lang("Ma_nhan_vien")}
                                        dataField={'EmployeeID'}
                                        allowEditing={false}
                                        fixed={true}
                                        minWidth={130}
                                      />
                                      <Column
                                        caption={Config.lang("Ten_nhan_vien")}
                                        dataField={'EmployeeName'}
                                        allowEditing={false}
                                        fixed={true}
                                        minWidth={180}
                                      />
                                      <Column
                                          dataField={"UserPictureURL"}
                                          visible={false}
                                      />
                                      <Column
                                        caption={Config.lang("Ca_vao_ra")}
                                        dataField={'ShiftInOut'}
                                        allowEditing={false}
                                        minWidth={180}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'ShiftID'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'AttendanceDate'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'ShiftID'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'PreOTFrom'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'PreOTTo'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        caption={Config.lang("Tang_ca_truoc")}
                                        visible={dataForm?.IsPreOT === 1}
                                        dataField={"PreOTFromPreOTTo"}
                                        editCellRender={e=>renderOT(e, "PreOTFrom" ,"PreOTTo")}
                                        headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        width={150}
                                        allowEditing={true}
                                      >
                                          <CustomRule
                                              validationCallback={e=>validateRequired(e, "PreOTFrom", "PreOTTo")}
                                              message="Giá trị bắt buộc nhập"/>
                                      </Column>
                                      <Column
                                          visible={false}
                                          dataField={'AfterOTFrom'}
                                          allowEditing={false}
                                      />
                                      <Column
                                          visible={false}
                                          dataField={'AfterOTTo'}
                                          allowEditing={false}
                                      />
                                      <Column
                                        caption={Config.lang("Tang_ca_sau")}
                                        visible={dataForm?.IsAfterOT === 1}
                                        dataField={"AfterOTFromAfterOTTo"}
                                        editCellRender={e=>renderOT(e, "AfterOTFrom" ,"AfterOTTo")}
                                        headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        width={150}
                                        allowEditing={true}
                                      >
                                          <CustomRule
                                              validationCallback={e=>validateRequired(e, "AfterOTFrom", "AfterOTTo")}
                                              message="Giá trị bắt buộc nhập"/>
                                      </Column>
                                      <Column
                                          visible={false}
                                          dataField={'BetweenOTFrom'}
                                          allowEditing={false}
                                      />
                                      <Column
                                          visible={false}
                                          dataField={'BetweenOTTo'}
                                          allowEditing={false}
                                      />
                                      <Column
                                        caption={Config.lang("Tang_ca_giua")}
                                        visible={dataForm?.IsBetweenOT === 1}
                                        dataField={"BetweenOTFromBetweenOTTo"}
                                        editCellRender={e=>renderOT(e, "BetweenOTFrom" ,"BetweenOTTo")}
                                        headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        width={150}
                                        allowEditing={true}
                                      >
                                          <CustomRule
                                              validationCallback={e=>validateRequired(e, "BetweenOTFrom", "BetweenOTTo")}
                                              message="Giá trị bắt buộc nhập"/>
                                      </Column>
                                      {dataCaptionGrid && dataCaptionGrid.map((item,indx) => {
                                          if(!item.Disabled) return null;
                                          return (
                                              <Column
                                                  key={indx}
                                                  caption={item.RefCaption}
                                                  dataField={item.RefID}
                                                  dataType={"boolean"}
                                                  allowEditing={!isModeView}
                                                  allowFiltering={false}
                                                  alignment={"center"}
                                                  headerCellRender={renderHeaderColumns}
                                                  allowCopying={true}
                                                  allowPasting={true}
                                              />
                                          )
                                      })}
                                      <Column
                                        caption={Config.lang("Loai_tach_tang_ca")}
                                        dataField={'OTTypeID'}
                                        editorOptions={{showClearButton:true}}
                                        lookup={{
                                            dataSource: dataCboOtType,
                                            valueExpr: "OTTypeID",
                                            displayExpr: "OTTypeName",
                                        }}
                                        headerCellRender={renderHeaderColumns}
                                            allowCopying={true}
                                            allowPasting={true}
                                        allowEditing={true}
                                        minWidth={90}
                                      />
                                      <Column
                                        caption={Config.lang("Ly_do_tang_ca")}
                                        dataField={'Reason'}
                                        headerCellRender={renderHeaderColumns}
                                            allowCopying={true}
                                            allowPasting={true}
                                        allowEditing={true}
                                        width={120}
                                      />
                                      <Column
                                        caption={Config.lang("Ghi_chu")}
                                        dataField={'Note'}
                                        headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        allowEditing={true}
                                        width={120}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'IsUpdate'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'Deleted'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'Updated'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'AddEmp'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'IsDel'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        visible={false}
                                        dataField={'IsCancel'}
                                        allowEditing={false}
                                      />
                                      <Column
                                        caption={" "}
                                        allowEditing={false}
                                        fixed={true}
                                        fixedPosition={"right"}
                                        width={120}
                                        visible={!Config.isMobile  && !isModeView}
                                        cellRender={cellRenderAction}
                                      />
                                      <Summary calculateCustomSummary={calculateSelectedRow}>
                                          <TotalItem
                                            column="IsCheck"
                                            displayFormat={`${Config.lang("DHR_Tong")}:`}
                                          />

                                          <TotalItem
                                            name={"RowsSummary"}
                                            summaryType="custom"
                                            displayFormat={"{0}"}
                                            showInColumn="EmployeeID"
                                          />
                                      </Summary>
                                  </GridContainer>
                              </Col>
                              </Row>
                          </AccordionDetails>
                      </Accordion>
                  </FormGroup>
                  <FormGroup>
                      <Accordion>
                          <AccordionSummary>
                              {Config.lang("Dinh_kem")}
                          </AccordionSummary>
                          <AccordionDetails>
                              <Row>
                                  <Col xs={12} sm={12} md={12}>
                                      <Attachments
                                        ref={attRef}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={ uploading || iPermission <= 1}
                                        maxLength={5}
                                        uploading={uploading}
                                        onUploading={onUploading}
                                        onChanged={onChangeAttachments}
                                      />
                                  </Col>
                              </Row>
                        </AccordionDetails>
                      </Accordion>
                  </FormGroup>
              </Modal.Content>
              <Modal.Actions style={{ justifyContent: "space-between" }}>
                  <div>
                      <Icon disabled={true}
                            name={"attachment"}
                            onClick={onAttachment}
                      />
                  </div>
                  <div>
                      {!isModeView && rowData?.IsUpdate !== 1 && <ButtonGeneral
                          name={Config.lang("Luu")}
                          typeButton={"save"}
                          disabled={!dataGridEmployee.filter(i=>!i.Deleted)?.length || (modeW29F2070 === "add" && (iPermission <= 1 || !isOT)) || (modeW29F2070 === "edit" && (iPermission <= 2 || !isOT))}
                          style={{textTransform: "uppercase"}}
                          size={"large"}
                          onClick={saveGrid}
                      />
                      }
                  </div>
              </Modal.Actions>
          </Modal>

      </>
    );
};

export default compose(
  connect(null,
    (dispatch) => ({
        w29f2072Actions: bindActionCreators(W29F2072Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
  ),
)(W29F2072Popup);
