import React, {useEffect, useState, useRef} from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import Api from "../../../../services/api";
import * as generalActions from "../../../../redux/general/general_actions";
import withStyles from "@material-ui/core/styles/withStyles";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Filter from "../../../filter/filter";
import moment from 'moment';

import _ from "lodash";
import Status from "../../../common/status/status";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../../common/icons";
import Divider from "@material-ui/core/Divider";
import {Col, FormGroup, Row} from "react-bootstrap";
import DateBoxPicker from "../../../common/form-material/date-box";
import {Combo} from "../../../common/form-material";
import W29F2061 from "../W29F2061/W29F2061"
import HistoryIcon from "@material-ui/icons/History";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";


const styles = theme => ({
    inline: {
        display: "inline"
    },
    colPrimary: {
        color: theme.palette.primary.main
    }
})

const W29F2060 = ({classes, getCboAppStatus, generalActions, location}) => {

    const [dataGrid, setDataGrid] = useState({rows: [], total: 0});
    const [dataModal, setDataModal] = useState({});
    const [transID, setTransID] = useState("");
    const [modeModal, setModeModal] = useState("add");
    const [dataCboShift, setDataCboShift] = useState([]);
    const [dateForm, setDateForm] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [iPermission, setIPermission] = useState(0);

    const [isLoadingGrid, setLoadingGrid] = useState(false);
    const [isLoadingCboShift, setLoadingCboShift] = useState(false);
    const [isLoadingCboAppStatus, setLoadingCboAppStatus] = useState(false);
    const [isShowModal, setShowModal] = useState(false);

    const paramsPaging = useRef({
        skip: 0,
        limit: 10,
    }).current;

    const shiftID = useRef("");
    const appStatusID = useRef("");
    const timerLoading = useRef(null);

    useEffect(() => {
        loadForm();
        loadPermission();
        return () => {
            if (timerLoading.current) clearTimeout(timerLoading.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Config.callChildForm({
            FormID: "W29F2060",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid.rows,
            onLoad: (params) => loadForm(_.get(params, "ID", "")),
            onAction: (e, data) => onOpenModal("view", data)
        }, {location});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    const loadPermission = async () => {
        await generalActions.getPermission("W29F2060", (isPer) => {
            setIPermission(isPer);
        });
    };

    const loadForm = async (TransID = "") => {
        setLoadingGrid(true);
        const params = {
            DivisionID: Config.getDivisionID(),
            DateFrom: dateForm,
            DateTo: dateTo,
            FormID: "W29F2060",
            Language: Config.language || "84",
            TransID,
            ShiftID: _.toString(_.get(shiftID, "current", null)),
            AppStatusID: _.toString(_.get(appStatusID, "current", null)),
            ...paramsPaging,
        }
        const res = await Api.put("/w29f2060/load-form", _.omitBy({
            ...params,
        }, _.isNull));
        timerLoading.current = setTimeout(() => setLoadingGrid(false), 300)
        const resData = _.get(res, "data", []);
        const resDataRows = _.get(resData, "rows", resData);
        const resDataTotal = _.get(resData, "total", _.size(resData));
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""))
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        setDataGrid({rows: resDataRows, total: resDataTotal});
        return true;
    };

    const loadCboShift = async () => {
        setLoadingCboShift(true);
        const res = await Api.put("/w29f2060/load-shift");
        const resData = _.get(res, "data", []);
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""))
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        setLoadingCboShift(false);
        setDataCboShift(resData);
        return true;
    };

    const loadCboAppStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || "84",
        }
        setLoadingCboAppStatus(true);
        generalActions.getCboAppStatus(params, (err) => {
            setLoadingCboAppStatus(false);
            if (err) {
                Config.popup2.show("ERROR", err);
                return false;
            }
        })
    };

    const onDelete = (e) => {
        Config.popup2.show("YES_NO", Config.lang('Ban_co_chac_chan_muon_xoa_du_lieu_nay'), async () => {
            const params = {
                TransID: _.get(e, "TransID", ""),
                FormID: "W29F2060"
            };
            const res = await Api.delete("/w29f2060/delete", params);
            const resData = _.get(res, "data", {});
            const resMessageErr = _.get(_.get(res, "message", _.get(resData, "Message", "")));
            if (resMessageErr) {
                Config.popup2.show("ERROR", resMessageErr);
                return false;
            }
            if (_.get(resData, "Status", 1) === 0) {
                Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                loadForm()
            }
        })
    };

    const onChangePage = (page) => {
        paramsPaging.skip = page * paramsPaging.limit;
        loadForm();
    };

    const onChangePerPage = (perPage) => {
        paramsPaging.skip = 0;
        paramsPaging.limit = perPage;
        loadForm();
    };

    const onLoadCboFilter = () => {
        loadCboShift();
        loadCboAppStatus();
    };

    const onFilter = () => {
        loadForm();
    };

    const onOpenModal = (mode = "add", data = {}) => {
        setModeModal(mode);
        loadCboShift();
        if (!_.isEmpty(data)) {
            setDataModal(data);
        } else {
            setDataModal({});
        }
        setShowModal(true);
    };

    const onCloseModal = (isReload = false) => {
        if (isReload) loadForm();
        setShowModal(false);
    };

    const renderFilter = () => {
        return <Filter
            placeholder={Config.lang("Noi_dung_can_tim_kiem")}
            openOnFieldClick={true}
            onOpenLoaded={onLoadCboFilter}
            renderFilter={() => (
                <>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col md={2} xs={12}>
                                <div style={{paddingTop: "25%"}}>{Config.lang("Ngay")}</div>
                            </Col>
                            <Col md={5} xs={12}>
                                <DateBoxPicker
                                    dateBoxProps={{max: dateTo}}
                                    onValueChanged={({value}) => setDateForm(value)}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    placeholder={"DD/MM/YYYY"}
                                    value={dateForm}
                                    disabled={isLoadingGrid}
                                />
                            </Col>
                            <Col md={5} xs={12}>
                                <DateBoxPicker
                                    dateBoxProps={{min: dateForm}}
                                    onValueChanged={({value}) => setDateTo(value)}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    placeholder={"DD/MM/YYYY"}
                                    value={dateTo}
                                    disabled={isLoadingGrid}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    label={Config.lang("Ca")}
                                    dataSource={dataCboShift}
                                    displayExpr={"ShiftName"}
                                    valueExpr={"ShiftID"}
                                    loading={isLoadingCboShift}
                                    disabled={isLoadingGrid}
                                    onValueChanged={({value}) => shiftID.current = value}
                                    showClearButton={true}
                                    value={shiftID.current}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    label={Config.lang("Trang_thai_duyet")}
                                    dataSource={getCboAppStatus}
                                    displayExpr={"AppStatusName"}
                                    valueExpr={"AppStatusID"}
                                    loading={isLoadingCboAppStatus}
                                    disabled={isLoadingGrid}
                                    onValueChanged={({value}) => appStatusID.current = value}
                                    showClearButton={true}
                                    value={appStatusID.current}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"display_row align-center valign-middle"}>
                        <ButtonGeneral
                            disabled={isLoadingGrid}
                            name={Config.lang("Tim_kiem")}
                            typeButton={"search"}
                            size={"large"}
                            color={"primary"}
                            variant={"outlined"}
                            onClick={onFilter}
                        />
                    </FormGroup>
                </>)
            }
        />
    };

    const renderInfo = ({data}) => {
        const attendanceDate = _.get(data, "AttendanceDate", "");
        const shiftID = _.get(data, "ShiftID", "");
        const shiftName = _.get(data, "ShiftName", "");
        const oTHour = _.get(data, "OTHour", "");
        const reasonOT = _.get(data, "ReasonOT", "");
        const approverName = _.get(data, "ApproverName", "");
        const approvalNote = _.get(data, "ApprovalNote", "");
        const dataField = _.filter([
            {
                label: Config.lang("Ngay"),
                value: moment(attendanceDate).format("DD/MM/YYYY"),
                separator: " ",
            },
            {
                label: shiftID,
                value: shiftName,
                separator: " ",
            },
            {
                label: Config.lang("Gio_tang_ca"),
                value: oTHour,
                separator: " ",
            },
            {
                label: Config.lang("Ly_do"),
                value: reasonOT,
                separator: ": ",
            },
            {
                label: Config.lang("Ghi_chu_duyet"),
                value: approvalNote,
                separator: ": ",
            },
            {
                label: Config.lang("Nguoi_duyet"),
                value: approverName,
                separator: ": ",
            }
        ], item => !!item.value).map(item => ({
            content: _.join(_.compact([item.label, item.value]), item.separator)
        }));
        return (<div>
            {_.map(dataField, (item, i) => (
                <div key={i} style={{paddingLeft: i === 1 ? 15 : 0}} className={i <= 1 ? classes.inline : ""}>
                    <span style={{fontWeight: 600}} className={i === 0 ? classes.colPrimary : ""}>{item.content}</span>
                </div>
            ))}
        </div>)
    };

    const renderStatus = ({data}) => <Status data={data}/>;

    const renderAction = ({data}) => (<div className={"display_row align-center"}>
        <IconButton aria-label="view" size="small" onClick={() => setTransID(_.get(data,"TransID", ""))}>
            <HistoryIcon/>
        </IconButton>
        <Divider
            orientation="vertical"
            style={{width: 2, height: 11, margin: "0 5px"}}
        />
        <IconButton
            aria-label="view"
            size="small"
            disabled={iPermission < 0}
            onClick={() => onOpenModal("view", data)}
        >
            <Icons name={"view"}/>
        </IconButton>
        <Divider
            orientation="vertical"
            style={{width: 2, height: 11, margin: "0 5px"}}
        />
        <IconButton
            disabled={iPermission < 3 || !!_.get(data, "AppStatusID", 1)}
            aria-label="delete"
            size="small"
            onClick={() => onDelete(data)}
        >
            <Icons name={"delete"}/>
        </IconButton>
    </div>);
    return <>
        <div className={"hidden"}>{renderFilter()}</div>
        <W84F3005
            open={!_.isEmpty(transID)}
            onClose={() => setTransID("")}
            FormID="W29F2060"
            TransID={transID} />
        {isShowModal &&
        <W29F2061
            isShowModal={isShowModal}
            iPermission={iPermission}
            modeForm={modeModal}
            data={dataModal}
            onClose={onCloseModal}
            dataCboShift={dataCboShift}
        />}
        <ActionToolbar title={Config.lang("Dang_ky_tang_ca_ho")}>
            <ButtonGeneral
                disabled={iPermission < 2}
                name={Config.lang("Them")}
                typeButton={"add"}
                style={{textTransform: "uppercase"}}
                size={"large"}
                onClick={() => onOpenModal()}
            />
        </ActionToolbar>
        <GridContainer
            style={{border: "none"}}
            dataSource={dataGrid.rows}
            itemPerPage={paramsPaging.limit}
            skipPerPage={paramsPaging.skip}
            totalItems={dataGrid.total}
            showRowLines={true}
            showBorders={false}
            showColumnLines={false}
            showColumnHeaders={false}
            rowAlternationEnabled={false}
            noDataText={Config.lang("No_data")}
            onDbCellClick={({data}) => iPermission > 1 ? onOpenModal(
                iPermission > 2 && _.get(data, "AppStatusID", 1) === 0
                    ? "edit"
                    : "view",
                data) : false
            }
            loading={isLoadingGrid}
            hoverStateEnabled={true}
            typePaging={"remote"}
            height={Config.getHeightGrid()}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
        >
            <Column width={"80%"} cellRender={renderInfo}/>
            <Column cellRender={renderStatus}/>
            <Column cellRender={renderAction}/>
        </GridContainer>
    </>
};

export default compose(
    connect((state) => ({
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles, {withTheme: true})
)(W29F2060);